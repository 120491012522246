//
//
//  Lesson
//
//

import {
  Button,
  Flex,
  Container,
  Stack,
  Title,
  LoadingOverlay,
  ActionIcon,
  Alert,
  Loader,
  Skeleton,
  Badge,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import classes from "./ExamEditor.module.css";
import { ExamInterface, ExamQuestionInterface } from "../../../interfaces.ts";
import DashboardHeader from "../../../components/DashboardHeader.tsx";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
} from "@tabler/icons-react";
import Question from "./Question.tsx";
import Api from "../../../api.ts";
import BackToExamsButton from "./BackToExamsButton.tsx";
import EditableText from "../../../components/EditableText.tsx";
import { useSocket } from "../../../contexts/SocketContext.tsx";
import { Socket } from "socket.io-client";
import { notifications } from "@mantine/notifications";
import GearRotator from "./GearRotator.tsx";

const PAGE_SIZE = 25;

function ExamEditor() {
  const { socket }: { socket: Socket } = useSocket();
  const { getAccessTokenSilently } = useAuth0();
  const [exam, setExam] = useState<ExamInterface>();
  const [examQuestions, setExamQuestions] = useState<ExamQuestionInterface[]>();
  const [questionsReviewed, setQuestionsReviewed] = useState<number>(0);
  const [allQuestionsValidated, setAllQuestionsValidated] = useState(false);
  const [questionSelected, setQuestionSelected] =
    useState<ExamQuestionInterface>();
  const [isAdding, setIsAdding] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const [updatingQuestionUuids, setUpdatingQuestionUuids] = useState<string[]>([]);
  const [discardedQuestionUuid, setDiscardedQuestionUuid] = useState<
    string | null
  >(null);
  const params = useParams();

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });

    return accessToken;
  }

  async function fetchExams() {
    if (!params.examUuid) {
      return;
    }
    Api.getExam(await getAccessToken(), params.examUuid).then((exam) => {
      setExam(exam);
    });
  }

  async function fetchExamQuestions() {
    if (!params.examUuid) {
      return;
    }
    setIsAdding(true);
    Api.getExamQuestions(
      await getAccessToken(),
      params.examUuid,
      1,
      PAGE_SIZE
    ).then((pageQuestions) => {
      setExamQuestions(pageQuestions.items);
      if (pageQuestions.items.length === 0) {
        return <Loader />;
      }
      setIsAdding(false);
    });
  }

  async function acceptQuestion(question_uuid: string) {
    setUpdatingQuestionUuids((prevUuids) => [...prevUuids, question_uuid]);
    Api.updateExamQuestion(await getAccessToken(), question_uuid, {
      accepted: true,
    }).then((updatedExamQuestion) => {
      setUpdatingQuestionUuids((prevUuids) =>
      prevUuids.filter((uuid) => uuid !== question_uuid)
  );
      setExamQuestions((prevQuestions) => {
        return prevQuestions?.map((prevQuestion) => {
          if (prevQuestion.uuid === updatedExamQuestion.uuid) {
            return updatedExamQuestion;
          }
          return prevQuestion;
        });
      });
    });
  }

  async function deployExam() {
    if (!exam) {
      return;
    }
    setIsDeploying(true);
    Api.updateExam(await getAccessToken(), exam.uuid, {
      status: "DEPLOYED",
    }).then((updatedExam) => {
      setExam(updatedExam);
      setIsDeploying(false);
      notifications.show({
        title: "Deployed!",
        message: "Exam deployed successfully!",
        color: "teal",
      });
    });
  }

  async function updateQuestion(newText: string, question_uuid: string) {
    Api.updateExamQuestion(await getAccessToken(), question_uuid, {
      text: newText,
    });
  }

  async function generateNewQuestion(deletedQuestion: ExamQuestionInterface) {
    setAllQuestionsValidated(false);
    setDiscardedQuestionUuid(deletedQuestion.uuid);
    if (deletedQuestion.uuid === questionSelected?.uuid) {
      setQuestionSelected(undefined);
    }
    Api.deleteExamQuestion(await getAccessToken(), deletedQuestion.uuid).then(
      async () => {
        if (!exam) {
          return;
        }
        Api.createExamQuestion(await getAccessToken(), exam.uuid).then(() => {
          setIsAdding(false);
        });
      }
    );
  }

  useEffect(() => {
    fetchExams();
  }, [params]);

  useEffect(() => {
    if (exam) {
      fetchExamQuestions();
    }
  }, [exam]);

  function generatingExamQuestions() {
    return (
      <Alert
      title="Generating Exam Questions"
      color="yellow"
      style={{ marginTop: "1rem" }}
    >
        <GearRotator/>
        <Text>Please wait while the exam questions are being generated. This process
        may take some time.
        </Text>
      </Alert>
    );
  }

  useEffect(() => {
    if (!examQuestions || !exam) {
      return;
    }
    if (exam.status !== "DRAFT") {
      return;
    }
    setQuestionsReviewed(
      examQuestions.filter((question) => question.accepted).length
    )
    if (examQuestions.every((question) => question.accepted)) {
      setAllQuestionsValidated(true);
    }
  }, [examQuestions]);

  useEffect(() => {
    function onExamUpdate(updatedExam: ExamInterface) {
      if (updatedExam.uuid === exam?.uuid) {
        setExam(updatedExam);
      }
    }
  
    function onExamQuestionAdd(newQuestion: ExamQuestionInterface) {
      if (newQuestion.exam_uuid === exam?.uuid) {
        newQuestion.new = true;
        setExamQuestions((prevQuestions) => {
          return [...(prevQuestions || []), newQuestion];
        });
      }
      setIsAdding(false);
    }

    function onExamQuestionDelete(deletedQuestion: ExamQuestionInterface) {
      if (deletedQuestion.exam_uuid === exam?.uuid) {
        setExamQuestions((prevQuestions) => {
          return prevQuestions?.filter(
            (prevQuestion) => prevQuestion.uuid !== deletedQuestion.uuid
          );
        });
      }
    }

    socket.on("exam:update", onExamUpdate);
    socket.on("exam_question:add", onExamQuestionAdd);
    socket.on("exam_question:delete", onExamQuestionDelete);

    return () => {
      socket.off("exam:update", onExamUpdate);
      socket.off("exam_question:add", onExamQuestionAdd);
      socket.off("exam_question:delete", onExamQuestionDelete);
    };
  }, [socket, examQuestions]);

  if (!exam) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <>
      <div className={classes.content}>
        <div className={classes.header}>
          <DashboardHeader
            title={exam.name}
            leftSection={BackToExamsButton}
            rightSection={
              <Flex gap={12}>
                {exam?.status === "DEPLOYED" ? (
                  <Badge variant="outline" color="GREEN" size="lg" radius="sm">DEPLOYED</Badge>
                ) : (
                  <Button
                    loading={isDeploying}
                    disabled={!allQuestionsValidated}
                    onClick={deployExam}
                  >
                    Deploy
                  </Button>
                )}
              </Flex>
            }
          />
        </div>
        <div
          style={{
            backgroundColor: "rgb(247, 247, 247)",
            minHeight: "calc(100vh)",
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <Container>
            <Flex justify={'space-between'} align={'center'} mt={25}>
            <Title mb={25}>Exam Editor</Title>
            <Badge>
              {questionsReviewed}/{exam.pool_size} questions reviewed
            </Badge>
            </Flex>
            <Stack>
              {exam.status === "GENERATING" || exam.status === "PENDING" ? (
                generatingExamQuestions()
              ) : exam.status === "ERROR" ? (
                <Alert title="Exam Generation Error" color="red">
                  There was an error processing the exam. Please try again
                  later.
                </Alert>
              ) : examQuestions ? (
                examQuestions.map(
                  (question: ExamQuestionInterface) => (
                    <div key={question.uuid}>
                      <div
                        className={`${classes.row} ${
                          discardedQuestionUuid === question.uuid
                            ? classes["slide-left"]
                            : isAdding && classes["slide-right"]
                        }`}
                        style={question.new ? { backgroundColor: "#F5FBEF" } : undefined}
                      >
                        <Flex justify={"space-between"} align={"center"}>
                          <Flex align={"center"} gap={10}>
                            <ActionIcon
                              variant="subtle"
                              onClick={() => {
                                setQuestionSelected(
                                  questionSelected === question
                                    ? undefined
                                    : question
                                );
                              }}
                            >
                              {questionSelected === question ? (
                                <IconChevronUp color="red" size={16} />
                              ) : (
                                <IconChevronDown color="red" size={16} />
                              )}
                            </ActionIcon>
                            <Title size="h3" className={classes.rowTitle}>
                              <EditableText
                                initialText={question.text}
                                onSave={(newText: string) =>
                                  updateQuestion(newText, question.uuid)
                                }
                                disabled={exam.status === "DEPLOYED"}
                              />
                            </Title>
                          </Flex>
                          {question.accepted ? (
                            <IconCircleCheck
                              size={26}
                              color="green"
                              stroke={1}
                            />
                          ) : (
                            <Flex gap={10}>
                              <Button
                                color="red"
                                variant="outline"
                                onClick={() => generateNewQuestion(question)}
                                loading={
                                  discardedQuestionUuid === question.uuid
                                }
                              >
                                Discard
                              </Button>
                              <Button
                                loading={updatingQuestionUuids.includes(question.uuid)}
                                onClick={() => acceptQuestion(question.uuid)}
                              >
                                Accept
                              </Button>
                            </Flex>
                          )}
                        </Flex>
                      </div>
                      {questionSelected &&
                        questionSelected.uuid === question.uuid && (
                          <Question
                            question={questionSelected}
                            disabled={exam.status === "DEPLOYED"}
                          />
                        )}
                    </div>
                  )
                )
              ) : (
                <Stack>
                  {[...Array(10)].map((_, i) => (
                    <Skeleton key={i} height={50} width="100%" />
                  ))}
                </Stack>
              )}
            </Stack>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ExamEditor;
