//
//
//  Utils
//
//
export function getAccessToken(): string | null {
    const localStorageAccessToken = localStorage.getItem("accessToken")
    const sessionStorageAccessToken = sessionStorage.getItem("accessToken")

    if (localStorageAccessToken != null) {
        return localStorageAccessToken
    } else if (sessionStorageAccessToken != null) {
        return sessionStorageAccessToken
    } else {
        return null
    }
}

export function generateColorFromString(text: string) {
    function hashCode(str: string) {
        let hash = 0;
        if (str.length === 0) return hash;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32-bit integer
        }
        return hash;
    }

    function intToRGB(i: number) {
        const c = (i & 0x00ffffff).toString(16).toUpperCase();
        return "#" + "00000".substring(0, 6 - c.length) + c;
    }

    const hash = hashCode(text);
    return intToRGB(hash);
}


export function getMetaContent(name: string) {
    const metaHTML = document.querySelector(`meta[name="${name}"]`)
    if (metaHTML != null) {
        return metaHTML.getAttribute("content")
    }
}


export function sortByUpdatedAt(array: Array<any>) {
    const copyArray = [...array]
    copyArray.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
    return copyArray
}


export function sortByCreatedAt(array: Array<any>) {
    const copyArray = [...array]
    copyArray.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    return copyArray
}


export function onResourceAdd(newResource: any, listResources: any[]) {
    const index = listResources.findIndex(resource => resource.uuid === newResource.uuid)
    if (index < 0) {
        listResources.push(newResource)
        return [...listResources]
    } else {
        return listResources
    }
}


export function onResourceUpdate(updatedResource: any, listResources: any[]) {
    return listResources.map(res => {
        return res.uuid === updatedResource.uuid ? updatedResource : res
    })
}


export function onResourceDelete(deletedResource: any, listResources: any[]) {
    return listResources.filter(res => res.uuid != deletedResource.uuid)
}


export function formatDate(date: Date): string {
    // Get the individual components (day, month, year, hours, minutes)
    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-based, so add 1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    // Add leading zeros to ensure two-digit formatting
    const formattedDay = day < 10 ? '0' + day : day
    const formattedMonth = month < 10 ? '0' + month : month
    const formattedHours = hours < 10 ? '0' + hours : hours
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

    // Create the formatted string
    return `${formattedDay}/${formattedMonth}/${year} @ ${formattedHours}:${formattedMinutes}`
}


export function formatRangeDate(date: Date | string): string {
    if (date instanceof Date) {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
    const parsedDate = new Date(date);
    
    if (!isNaN(parsedDate.getTime())) {
        return `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;
    }
    return '';
}


export function intToHoursMinutesSeconds(totalSeconds: number): string {

    totalSeconds = Math.round(totalSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


export function timeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(part => parseInt(part, 10));
    return hours * 3600 + minutes * 60 + seconds;
  }

  