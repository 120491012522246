//
//
// Update Drawer
//
//


import { Box, Button, Drawer, Group, TextInput, Textarea, Select, Checkbox } from "@mantine/core";
import { useEffect } from "react";
import { AzureVoice, Personality } from "../interfaces";
import { useForm } from "@mantine/form";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import Api from "../api"

interface UpdateDrawerProps {
    opened: boolean,
    onClose: ()=>void,
    personality: Personality,
    onUpdate: (values: string)=>void,
}

function UpdateDrawer (props: UpdateDrawerProps) {
    const { getAccessTokenSilently } = useAuth0()
    const {opened, onClose, personality, onUpdate} = props
    const [azureVoices, setAzureVoices] = useState<AzureVoice[]>([])
    const [accessToken, setAccessToken] = useState('')

    const form = useForm({
        initialValues: {
            name: personality.name,
            description: personality.description,
            context: personality.context,
            azure_voice_uuid: personality.azure_voice?.uuid || undefined,
            active: personality.active,
            default_text_max_sms: personality.default_text_max_sms,
            max_sms: personality.max_sms
        }
    });

    useEffect(() => {
        form.setValues({
            name: personality.name,
            description: personality.description,
            context: personality.context,
            azure_voice_uuid: personality.azure_voice?.uuid,
            active: personality.active,
            default_text_max_sms: personality.default_text_max_sms,
            max_sms: personality.max_sms
        })
    }, [personality])

    useEffect(() => {
        const fetchAccessToken = async () => {
          try {
            const token = await getAccessTokenSilently({
              authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
              },
            });
            setAccessToken(token)
          } catch (error) {
            console.error(error);
          }
        };
        fetchAccessToken();
      }, []);

    useEffect(() => {
        fetchAzureVoices()
      }, [accessToken])

    useEffect(()=>{
        form.setValues({
            name: personality.name,
            description: personality.description,
            context: personality.context,
            azure_voice_uuid: personality.azure_voice?.uuid,
            default_text_max_sms: personality.default_text_max_sms,
            max_sms: personality.max_sms,
        })
    },[personality.uuid])

    async function fetchAzureVoices() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })
         Api.getAzureVoices(accessToken)
            .then((pageAzureVoices) => {
                setAzureVoices(pageAzureVoices["items"])                
            }
            )
    }

    function onSubmit(values: any) {
        onUpdate(values)
        onClose()
    }

      return (<>
            <Drawer opened={opened} onClose={onClose} position="right" title="Edit Personality">
            <Box maw={340} mx="auto">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput
                        label="Name"
                        {...form.getInputProps("name")}
                    />
                    <TextInput
                        label="Description"
                        {...form.getInputProps("description")}
                    />
                    <Textarea
                        label="Context"
                        {...form.getInputProps("context")}
                    />
                    <Select label="Azure Voice" 
                        {...form.getInputProps("azure_voice_uuid")}
                        placeholder="Select Azure voice"
                        clearable
                        searchable                        
                        data={azureVoices.map((voice) => ({ value: voice.uuid, label: voice.name }))}                 
                        // fetching={isLoading}
                    />                   
                    <Checkbox
                        label="Active"
                        style={{marginTop: '10px'}}
                        {...form.getInputProps("active" , { type: 'checkbox' })}
                    />

                    <Group justify="flex-end" mt="md">
                        <Button type="submit" style={{position: 'static', right: 0}}>Submit</Button>
                    </Group>
                </form>
            </Box>
        </Drawer>
      </>)
}

export default UpdateDrawer