//
//
//  Conversation
//
//


import {Button, Flex, Title, Text, Avatar, LoadingOverlay, Paper, Container, Tooltip, Divider} from "@mantine/core";
import { IconTrash, IconRobot, IconHelp, IconRefresh } from "@tabler/icons-react";
import { useEffect, useState} from "react";
import {modals} from "@mantine/modals";
import {useAuth0} from "@auth0/auth0-react";
import Api from "../../api.ts";
import { notifications } from '@mantine/notifications';
import { useTeam } from "../../contexts/TeamContext.tsx";
import { ConversationInterface, ConversationMessageInterface, Personality, TeamMember } from "../../interfaces.ts";
import classes from "./Index.module.css"
import { formatDate } from "../../utils.ts";


const PAGE_SIZE = 25

type FlexDirection = "column" | "column-reverse" | "row" | "row-reverse";

function Conversation({conversation, onDelete}: {conversation: ConversationInterface, onDelete: (conversation: ConversationInterface) => void}) {
    const {role} = useTeam()
    const {getAccessTokenSilently} = useAuth0()
    const [totalMessages, setTotalMessages] = useState(0)
    const [messagesLoading, setMessagesLoading] = useState<boolean>(false)
    const [messages, setMessages] = useState<ConversationMessageInterface[]>([])
    const [teamMember, setTeamMember] = useState<TeamMember>()
    const [personality, setPersonality] = useState<Personality>()
    const [code, setCode] = useState<string>("")
    const [loadingCode, setLoadingCode] = useState<boolean>(false)

    let lastMessageDate: string = ""
    let newDate: boolean = false

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function fetchMessages() {
        setMessagesLoading(true)
        Api.getConversationMessages(await getAccessToken(), conversation.uuid, 1, PAGE_SIZE)
            .then((response) => {
                setMessages(response["items"])
                setTotalMessages(response["total"])
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setMessagesLoading(false)
            }) 
    }

    async function getTeamMember() {
        Api.getTeamMemberByUuid(await getAccessToken(), conversation.team_member_uuid)
            .then((teamMember) => {
                setTeamMember(teamMember)
            }).catch(error => {
                console.error(error)
            })
    }

    async function getPersonality() {
        Api.getPersonality(await getAccessToken(), conversation.personality_uuid)
            .then((personality) => {
                setPersonality(personality)
            }).catch(error => {
                console.error(error)
            })
    }

    async function getConversationCode() {
        setLoadingCode(true)
        Api.getConversationCode(await getAccessToken(), conversation.uuid)
            .then((response) => {
                setCode(response.code)
                setLoadingCode(false)
            }).catch(error => {
                console.error(error)
            })
    }

    async function refreshConversationCode() {
        setLoadingCode(true)
        Api.createConversationCode(await getAccessToken(), conversation.uuid)
            .then((response) => {
                setCode(response.code)
                setLoadingCode(false)
            }).catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        getTeamMember()
        getPersonality()
        getConversationCode()
        fetchMessages()
    }, [conversation.uuid, /*messagesPage*/])


    const deleteConversationModal = () => {
        modals.openConfirmModal({
            title: "Delete Conversation",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete the conversation? This action is destructive and you will not be able to recover the conversation once it's deleted.
                </Text>
            ),
            labels: { confirm: 'Delete conversation', cancel: "No don't delete it" },
            confirmProps: { color: 'red' },
            onCancel: () => {
                modals.closeAll();
            },
            onConfirm: async () => { 
                try {
                    const accessToken = await getAccessToken();
                    await Api.deleteConversation(accessToken, conversation.uuid);
                    onDelete(conversation);
                } catch (error) {
                    const message = 'Oops... something went wrong. Try again later'
                    notifications.show(
                        {
                            title: 'Error',
                            message,
                            color: 'red'
                        }
                    )

                }
            },
        });
    };


    return (
        <Flex direction="column" style={{width: "100%"}} gap={20} ml={300}>
            <Flex direction="row" mt={24} justify="space-between" style={{width: "100%"}}>
                <Flex direction="row" gap={10}>
                    Conversation started at <Text fw={700}>{formatDate(new Date(conversation.created_at))}</Text>
                </Flex>
                {role === 'EDITOR' && (
                <Flex align="center" gap={20}>
                    <Button leftSection={<IconTrash size={16}/>} color="red" size="xs" onClick={deleteConversationModal}>
                        Delete
                    </Button>
                </Flex>
                )}
            </Flex>

            <Flex direction="row" gap={8} align={"center"} mt={20} style={{width: "100%"}}>
                <Title order={5}>Conversation code</Title>
                <Tooltip
                    label="Code required at the beginning of a conversation. It expires after 10 minutes."
                    withArrow
                >
                    <IconHelp color="grey" size={20}/>
                </Tooltip>
            </Flex>

            <Flex direction="row" style={{width: "100%"}}>
                {code === null ? 
                <Flex gap={40} direction="row" align={"center"}>
                    <Button size="xs" variant="outline" color="blue" leftSection={<IconRefresh size={16}/>}
                            onClick={refreshConversationCode} loading={loadingCode}>
                        Refresh code
                    </Button>
                </Flex>
                :
                <Flex pos="relative">
                    <LoadingOverlay visible={loadingCode} />
                    <Title order={1} mb={12}>{code}</Title>
                </Flex>
                }
            </Flex>

            <Flex direction="column">
                <Title order={5} mb={12}>Messages</Title>
                <Paper withBorder={true} p="xl">
                    <Container className={classes.mainContainer}>
                        <LoadingOverlay visible={messagesLoading} />
                        <Flex className={classes.chat} gap={40} direction="column" mt={15} mb={30}>
                            {totalMessages > 0 ?
                                messages.map((message) => {
                                    let avatar = undefined
                                    let name = undefined
                                    let direction = undefined
                                    let color = undefined
                                    const timestamp = new Date(message.created_at)
                                    
                                    const time = (
                                        timestamp.getHours().toString().padStart(2, '0') + ":" +
                                        timestamp.getMinutes().toString().padStart(2, '0')
                                    )

                                    const date = (
                                        timestamp.getDate().toString().padStart(2, '0') + "/" +
                                        (timestamp.getMonth() + 1).toString().padStart(2, '0') + "/" +
                                        timestamp.getFullYear().toString()
                                    )

                                    if (lastMessageDate !== date) {
                                        lastMessageDate = date
                                        newDate = true
                                    } else {
                                        newDate = false
                                    }

                                    if (message.is_user) {
                                        avatar = (
                                            <Avatar size="sm" radius="xl" src={teamMember?.user.picture}/>
                                        )
                                        name = teamMember?.user.name
                                        direction = "row-reverse"
                                        color = "#d7f3fe"
                                    } else {
                                        avatar = (
                                            <Avatar size="sm" radius="xl">
                                                <IconRobot size={16}/>
                                            </Avatar>
                                        )
                                        name = personality?.name
                                        direction = "row"
                                        color = "#fefdd7"
                                        
                                    }

                                    return (
                                        <>
                                        {newDate &&
                                            <Divider my="xs" label={date} labelPosition="center" />
                                        }

                                        <Flex key={message.uuid} gap={14} direction={direction as FlexDirection}>
                                            {avatar}
                                            <Flex className={classes.chatMessage} direction="column" gap={5}>
                                                <Flex align="center" direction={direction as FlexDirection} gap={20}>
                                                    <Text fw={700}>{name}</Text> <Text size="xs">{time}</Text>
                                                </Flex>
                                                <Flex align="center" direction={direction as FlexDirection} gap={6}>
                                                    <Paper bg={color} p={"xs"}>
                                                        <Text>{message.text}</Text>
                                                    </Paper>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        </>
                                    )
                                })
                            :
                                <Flex justify="center" align="center" direction="column" gap={20}>
                                    <Text size="sm">No messages yet</Text>
                                </Flex>
                            }
                            </Flex>
                        </Container>
                </Paper>
            </Flex>
        </Flex>
    )
}

export default Conversation