import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Button, Flex, Text, Title } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { notifications } from '@mantine/notifications';
import { IconMessage2, IconMoodSad } from "@tabler/icons-react";

import Api from "../../api";
import DashboardHeader from "../../components/DashboardHeader";
import classes from "./Index.module.css";
import Conversation from "./Conversation";
import { useTeam } from "../../contexts/TeamContext";
import { ConversationInterface, Personality } from "../../interfaces";
import { formatDate, sortByCreatedAt } from "../../utils";


function Conversations() {
    const { getAccessTokenSilently } = useAuth0();
    const {team, role, teamMemberUuid} = useTeam()
    const [conversations, setConversations] = useState<ConversationInterface[]>([]);
    const [personalities, setPersonalities] = useState<Personality[]>([]);
    const pageLength = 20;
    const [conversationsLoading, setConversationsLoading] = useState(false);
    const [personalitiesLoading, setPersonalitiesLoading] = useState(false);
    const [page] = useState(1);
    const [selectedConversation, setSelectedConversation] = useState<ConversationInterface>();

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })
        return accessToken
    }

    useEffect(() => {
        fetchConversations();
    }, [getAccessTokenSilently, teamMemberUuid])

    useEffect(() => {
        fetchPersonalities();
    }, [team])

    async function createConversation() {
        setConversationsLoading(true)
        try {
            if (personalities.length === 0) {
                notifications.show(
                    {
                        title: 'Oops!',
                        "message": "No profiles found",
                        color: 'red'
                    }
                )

                setConversationsLoading(false)
                return;
            }
            const personality = personalities[Math.floor(Math.random() * personalities.length)]

            Api.createConversation(await getAccessToken(), teamMemberUuid, personality.uuid)
                .then((response) => {
                    setConversations(sortByCreatedAt([...conversations, response]))
                    setSelectedConversation(response)
                })
                .catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setConversationsLoading(false)
                })
        } catch (error) {
            console.error(error)
        }
    }


    async function fetchConversations() {
        setConversationsLoading(true)
        try {
            Api.getTeamMemberConversations(await getAccessToken(), teamMemberUuid, page, pageLength)
            .then((pageConversations) => {
                let conversationToSelect = null
                const cacheSelectedConversationUudText = sessionStorage.getItem("dashboard-trainer-selected-conversation")
                if (cacheSelectedConversationUudText !== null) {
                    conversationToSelect = pageConversations.items.find((conversation: ConversationInterface) => {
                        const isMatch = conversation.uuid === cacheSelectedConversationUudText;
                        return isMatch;
                    });
                }
                if (conversationToSelect == null && selectedConversation == null) {
                    if (pageConversations.items.length > 0) {
                        conversationToSelect = pageConversations.items[0]
                    } else {
                        setSelectedConversation(undefined)
                        setConversations([])
                        setConversationsLoading(false)
                        return;
                    }
                }

                setSelectedConversation(conversationToSelect)
                setConversations(pageConversations.items)
                setConversationsLoading(false)
            })
            .catch((error) => {
                console.error(error)
            })
        } catch (error) {
            console.error(error)
        }
    }


    async function fetchPersonalities() {
        setPersonalitiesLoading(true)
        try {
            Api.getTeamPersonalities(await getAccessToken(), team!.uuid, page, pageLength)
            .then((pagePersonalities) => {
                setPersonalities(pagePersonalities.items)
                setPersonalitiesLoading(false)
            })
            .catch((error) => {
                console.error(error)
            })
        } catch (error) {
            console.error(error)
        }
    }


    function onSelectConversation(newConversation: any) {
        setSelectedConversation(newConversation)
        sessionStorage.setItem("dashboard-trainer-selected-conversation", newConversation.uuid)
    }

const header = (
    <div className={classes.header}>
        <DashboardHeader
            rightSection={role === 'VIEWER' && <Button loading={personalitiesLoading} onClick={createConversation}>Start conversation</Button>}
            title="Conversations"
        />
    </div>
    );


    return (
        <>
            {header}
            <Flex mt={50}>
                <nav className={classes.nav}>
                    <DataTable
                        noHeader
                        fetching={conversationsLoading}
                        records={conversations}
                        withRowBorders={false}
                        noRecordsText="There are no conversations yet"
                        columns={[
                            {
                                accessor: "title",
                                ellipsis: true,
                                width: 300,
                                render: (conversation) => {
                                    return (
                                        <Button
                                            size="xs"
                                            onClick={() => onSelectConversation(conversation)}
                                            variant={conversation.uuid == selectedConversation?.uuid ? "filled" : "outline"}
                                            key={conversation.uuid}
                                            style={{width: "100%"}}
                                            classNames={{inner: classes.navButton}}
                                        >
                                            <Flex gap={10} align="center" className={classes.conversationNameContainer}>
                                                <IconMessage2 size={16}/>
                                                <Text size="sm" className={classes.conversationName}>{formatDate(new Date(conversation.created_at))}</Text>
                                            </Flex>
                                        </Button>
                                    )
                                }
                            }
                        ]}
                        className={classes.navTable}
                        minHeight={conversations.length > 0 ? 0 : 300}
                        emptyState={
                            <>
                                <Flex justify="center" align="center" direction="column" gap={20}>
                                    <IconMoodSad size={60}/>
                                    <Title order={4}>No conversations found</Title>
                                    {role === 'VIEWER' && <Button loading={personalitiesLoading} onClick={createConversation}>Start conversation</Button>}
                                </Flex>
                            </>
                        }
                    />
                </nav>
                <Flex ml={20} mr={20} style={{width: "100%"}}>
                    {selectedConversation &&
                        <Conversation
                            conversation={selectedConversation}
                            onDelete={(deletedConversation: any) => {
                                setConversations(conversations.filter(conversation => conversation.uuid != deletedConversation.id))
                                setSelectedConversation(undefined)
                            }}
                        />
                    }
                </Flex>
            </Flex>
        </>
    );
}

export default Conversations;
