import { useEffect, useRef, useState } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { User, useAuth0 } from "@auth0/auth0-react"
import { Socket } from "socket.io-client";

import { ActionIcon, Divider, Table, Avatar, LoadingOverlay, Select, ComboboxItem, Button, Flex, rem, Container, Box } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconCirclePlus, IconEdit, IconTrash } from '@tabler/icons-react'

import classes from "./TeamMembersTable.module.css"
import { Team, TeamMember } from '../../../../interfaces'
import Api from '../../../../api'
import { useApiErrorHandler } from '../../../../hooks'
import PaginationControl from '../../../../components/PaginationControl'
import TeamMemberEditModal from './Edit'
import {useSocket} from "../../../../contexts/SocketContext.tsx";
import {onResourceAdd, onResourceDelete, onResourceUpdate} from "../../../../utils.ts";



interface TeamMemberForm {
    userId: string,
    role: string
}

function TeamMembersTable({ team }: { team: Team }) {
    const pageLength = 5

    const [parent] = useAutoAnimate()
    const { getAccessTokenSilently } = useAuth0()
    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const [users, setUsers] = useState<ComboboxItem[]>([])
    const [loadingUsers, setLoadingUsers] = useState(true)
    const [loadingTeamMembers, setLoadingTeamMembers] = useState(true)
    const teamMembersRef = useRef<TeamMember[]>([])
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([])
    const [totalTeamMembers, setTotalTeamMembers] = useState(0)
    const [page, setPage] = useState(1)
    const [selectedTeamMember, setSelectedTeamMember] = useState<TeamMember | null>(null)
    const [openedEditMember, setOpenedEditMember] = useState(false)

    const humanReadableRoles = {
        "EDITOR": "Teacher",
        "VIEWER": "Student"
    }

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadTeamMembers() {
        if (team !== null) {
            setLoadingTeamMembers(true)
    
            Api.getTeamMembers(await getAccessToken(), team.uuid, page, pageLength)
                .then(listTeamMembers => {
                    teamMembersRef.current = listTeamMembers.items
                    setTeamMembers(teamMembersRef.current)
                    setTotalTeamMembers(listTeamMembers.total)
                    setLoadingTeamMembers(false)
                }).catch((err) => {
                    console.error(err);
                    handleError(err)
                    setLoadingTeamMembers(false)
                })
        }
    }

    async function loadUsers() {
        setLoadingUsers(true)
        
        Api.getUsers(await getAccessToken())
            .then(users => {
                const aux = users.map((user: User) => {
                    return {
                        "label": user.name + " <" + user.email + ">",
                        "value": user.id
                    } as ComboboxItem
                })

                if (aux !== undefined) {
                    setUsers(aux)
                }
                setLoadingUsers(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoadingUsers(false)
            })
    }


    useEffect(() => {
        loadTeamMembers()
        loadUsers()
    }, [team?.uuid])

    useEffect(() => {
        loadTeamMembers()
    }, [page])


    useEffect(() => {
        function onTeamMemberAdd(added: TeamMember) {
            teamMembersRef.current = onResourceAdd(added, teamMembersRef.current)
            loadTeamMembers()
        }

        function onTeamMemberUpdate(updated: TeamMember) {
            teamMembersRef.current = onResourceUpdate(updated, teamMembersRef.current)
            setTeamMembers(teamMembersRef.current)
        }

        function onTeamMemberDelete(deleted: TeamMember) {
            teamMembersRef.current = onResourceDelete(deleted, teamMembersRef.current)
            loadTeamMembers()
        }

        socket.on("teamMember:add", onTeamMemberAdd)
        socket.on("teamMember:update", onTeamMemberUpdate)
        socket.on("teamMember:delete", onTeamMemberDelete)

        return () => {
            socket.off("teamMember:add", onTeamMemberAdd)
            socket.off("teamMember:update", onTeamMemberUpdate)
            socket.off("teamMember:delete", onTeamMemberDelete)
        }
    }, [socket])

    const createTeamMemberform = useForm({
        initialValues: {
            userId: "",
            role: "VIEWER"
        }
    })

    async function onSubmitCreateTeamMember(values: TeamMemberForm) {
        setLoadingUsers(true)

        Api.createTeamMember(await getAccessToken(), team.uuid, values.userId, values.role)
            .then(() => {
                createTeamMemberform.reset()
                setLoadingUsers(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoadingUsers(false)
            })
    }

    function onSelectTeamMember(teamMember: TeamMember) {
        setSelectedTeamMember(teamMember)
        setOpenedEditMember(true)
    }

    async function deleteTeamMember(teamMemberUuid: string) {
        Api.deleteTeamMember(await getAccessToken(), teamMemberUuid)
            .catch(console.error)
        
        localStorage.removeItem("teamUuid")
    }

    return (
        <>
            <LoadingOverlay visible={loadingUsers}/>
            {users && (
                <form onSubmit={createTeamMemberform.onSubmit(onSubmitCreateTeamMember)}>
                    <Flex
                        align="flex-end"
                        direction="row"
                        mb={rem(30)}
                    >
                        <Select
                            label="User"
                            placeholder="Add new team member"
                            data={users}
                            searchable
                            {...createTeamMemberform.getInputProps("userId")}
                            mr={rem(20)}
                            style={{ width: '100%' }}
                        />

                        <Select
                            label="Role"
                            allowDeselect={false}
                            data={[{"label": "Student", "value": "VIEWER"}, {"label": "Teacher", "value": "EDITOR"}]}
                            {...createTeamMemberform.getInputProps("role")}
                            mr={rem(20)}
                        />

                        <Button
                            type="submit"
                            loading={loadingUsers}
                        >
                            <IconCirclePlus/>
                        </Button>
                    </Flex>
                    
                </form>
            )}
            <Divider my="sm" />
            <div className={classes.content}>
                <Box pos="relative">
                    <LoadingOverlay visible={loadingTeamMembers}/>
                    
                    {teamMembers && (
                        <Container style={{"width": "100%"}}>
                        <Table mt="md" withTableBorder>
                            <Table.Tbody ref={parent}>
                                {teamMembers.map(teamMember => {
                                    return (
                                        <Table.Tr key={teamMember.uuid}>
                                            <Table.Td>
                                                <Avatar src={teamMember.user.picture}/>
                                            </Table.Td>
                                            <Table.Td>
                                                {teamMember.user.name}
                                            </Table.Td>
                                            <Table.Td>
                                                {teamMember.user.email}
                                            </Table.Td>
                                            <Table.Td>
                                                {humanReadableRoles[teamMember.role as keyof typeof humanReadableRoles]}
                                            </Table.Td>
                                            <Table.Td>
                                                <ActionIcon variant="subtle" color="blue" aria-label={"Edit"} onClick={() => onSelectTeamMember(teamMember)}>
                                                    <IconEdit size={16} />
                                                </ActionIcon>
                                            </Table.Td>
                                            <Table.Td>
                                                <ActionIcon variant="subtle" color="red" aria-label={"Delete"} onClick={() => deleteTeamMember(teamMember.uuid)}>
                                                    <IconTrash size={16} />
                                                </ActionIcon>
                                            </Table.Td>
                                        </Table.Tr>
                                    )
                                })}
                            </Table.Tbody>
                        </Table>

                        <PaginationControl 
                            totalElements={totalTeamMembers}
                            page={page}
                            pageLength={pageLength}
                            onChange={setPage}
                        />
                        </Container>
                    )}
                    </Box>
            </div>

            {selectedTeamMember &&
                <TeamMemberEditModal
                    member={selectedTeamMember}
                    opened={openedEditMember}
                    setOpened={setOpenedEditMember}
                />
            }
        </>
    )
}

export default TeamMembersTable