//
//
//  API
//
//


import {LessonArticle, LessonInterface, Personality, Team} from "./interfaces.ts";

export class RequestError extends Error {

    response: Response

    constructor(response: Response) {
        super()
        this.response = response
    }
}

export function toJsonOrRaise(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }
    return response.json()
}

export function raiseForStatus(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }
    return response
}

export class AuthenticationError extends Error {}


class Api {

    static async getUsers(accessToken: string) {
        if (accessToken == null) {
            return null;
        }
        
        const response = await fetch(`${import.meta.env.VITE_URL}/api/user`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
        return await toJsonOrRaise(response);
    }

    static async getPersonality(accessToken: string, personalityUuid: string) {
        if (accessToken == null) {
            return null;
        }

        const response = await fetch(`${import.meta.env.VITE_URL}/api/personality/${personalityUuid}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
        return await toJsonOrRaise(response);
    }

    static async getPersonalities(accessToken: string, teamUuid: string, page: number, size: number=20) {
        if (accessToken == null) {
            return null;
        }
        const queryParams = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            });

        const response = await fetch(`${import.meta.env.VITE_URL}/api/team/${teamUuid}/personalities?${queryParams}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
        return await toJsonOrRaise(response);
    }

    static async createPersonality(accessToken: string, name: string, description: string, context: string, azure_voice_uuid: string, active: boolean, team_uuid: string, default_text_max_sms: string, max_sms: string): Promise<Personality | null> {
        if (accessToken == null) {
            return null;
        }

        const response = await fetch(`${import.meta.env.VITE_URL}/api/personality`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                description,
                context,
                azure_voice_uuid,
                active,
                team_uuid,
                default_text_max_sms,
                max_sms
            })
        });
        return await toJsonOrRaise(response);
    }

    static async updatePersonality(accessToken: string, personalityId: string, values: any): Promise<Personality | null> {

        if (accessToken == null) {
            return null;
        }

        const response = await fetch(`${import.meta.env.VITE_URL}/api/personality/${personalityId}`, {
            method: 'PATCH',
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(values)
        });
        return await toJsonOrRaise(response);
    }

    static async deletePersonality(accessToken: string, personalityId: string) {

        if (accessToken == null) {
            return null;
        }

        const response = await fetch(`${import.meta.env.VITE_URL}/api/personality/${personalityId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${accessToken}`                
            }
        });
        return await raiseForStatus(response);
    }

    static async getAzureVoices(accessToken: string) {
        if (accessToken == null) {
            return null;
        }
        
        const response = await fetch(`${import.meta.env.VITE_URL}/api/azure_voice`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
        return await toJsonOrRaise(response);
    }

    static async createLesson(accessToken: string, name: string, description: string, teamUuid: string): Promise<LessonInterface> {
        const response = await fetch(`${import.meta.env.VITE_URL}/api/lesson`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                description: description,
                team_uuid: teamUuid,
                disabled: false
            })
        });
        return await toJsonOrRaise(response);
    }
    
    static async getTeams(accessToken: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + "/api/team?" + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getTeamsBelong(accessToken: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + "/api/team/belong?" + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getTeam(accessToken: string, teamUuid: string): Promise<Team | null> {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        })

        return toJsonOrRaise(response)
    }

    static async createTeam(accessToken: string, name: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_URL + `/api/team`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "name": name
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateTeam(accessToken: string, teamUuid: string, values: NonNullable<unknown>): Promise<Team | null> {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })

        return toJsonOrRaise(response)
    }

    static async deleteTeam(accessToken: string, teamUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return raiseForStatus(response)
    }

    static async getTeamMembers(accessToken: string, teamUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}/members?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getTeamPersonalities(accessToken: string, teamUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}/personalities?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getTeamMember(accessToken: string, teamUuid: string, userId: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}/members/${userId}?`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getTeamMemberByUuid(accessToken: string, teamMemberUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team_member/${teamMemberUuid}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async createTeamMember(accessToken: string, teamUuid: string, userId: string, role: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_URL + `/api/team_member`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "team_uuid": teamUuid,
                "user_id": userId,
                "role": role
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateTeamMember(accessToken: string, teamMemberUuid: string, values: NonNullable<unknown>): Promise<Team | null> {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team_member/${teamMemberUuid}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })

        return toJsonOrRaise(response)
    }

    static async deleteTeamMember(accessToken: string, teamMemberUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team_member/${teamMemberUuid}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return raiseForStatus(response)
    }

    static async getTeamLessons(accessToken: string, teamUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}/lessons?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async updateTeamLesson(getAccessTokenSilently: () => Promise<string>, lessonId: string | number, name?: string, description?: string, disabled?: boolean): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_URL + "/api/lesson/" + lessonId, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, description, disabled})
        })
        return toJsonOrRaise(response)
    }

    static async deleteTeamLesson(accessToken: string, lessonUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson/${lessonUuid}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        })
        return raiseForStatus(response)
        
    }

    static async getLessonArticles(accessToken: string, lessonUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson/${lessonUuid}/articles?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getLessonArticlesDeployed(accessToken: string, lessonUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson/${lessonUuid}/articles/deployed?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async deleteLessonArticle(accessToken: string, articleUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article/${articleUuid}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }
    
    static async createLessonArticle(accessToken: string, lessonUuid: string | number, title: string): Promise<LessonArticle> {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: title,
                lesson_uuid: lessonUuid
            })
        })
        return toJsonOrRaise(response)
    }

    static async patchLessonArticle(accessToken: string, articleUuid: string, values: any) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article/${articleUuid}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async createLessonArticleContentByFile(accessToken: string, articleUuid: string, file: File) {
        const formData = new FormData()
        formData.append('file', file, file.name)

        const response = await fetch(import.meta.env.VITE_URL +`/api/lesson_article/${articleUuid}/content/file`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData
        })
        return raiseForStatus(response)
    }

    static async getLessonArticle(accessToken: string, articleUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article/${articleUuid}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async updateLessonArticleContent(accessToken: string, articleUuid: string, draft_content: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article/${articleUuid}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                draft_content
            })
        })
        return toJsonOrRaise(response)
    }

    static async createExam(accessToken: string, name: string, lessonUuid: string, timeLimit: number, poolSize: number) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name:name,
                lesson_uuid: lessonUuid,
                time_limit: timeLimit,
                pool_size: poolSize,
                status: "PENDING"
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateExam(accessToken: string, examUuid: string, values: any) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam/${examUuid}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }, 
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }
    static async getTeamExams(accessToken: string, teamUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}/exams?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getTeamExamsDeployed(accessToken: string, teamUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team/${teamUuid}/exams/deployed?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getExam(accessToken: string, examUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam/${examUuid}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        
        })
        return toJsonOrRaise(response)
    }

    static async deleteExam(accessToken: string, examUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam/${examUuid}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })
        return raiseForStatus(response)
    }

    static async getExamQuestions(accessToken: string, examUuid: string,  page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam/${examUuid}/questions?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async updateExamQuestion(accessToken: string, questionUuid: string, values: any) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_question/${questionUuid}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getExamQuestionAnswers(accessToken: string, questionUuid: string,  page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_question/${questionUuid}/answers?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
        
        return toJsonOrRaise(response)
    }

    static async getExamAttemptQuestionAnswers(accessToken: string, questionUuid: string,  page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_question/${questionUuid}/answers_text?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
        
        return toJsonOrRaise(response)
    }

    static async updateExamAnswer(accessToken: string, questionAnswerUuid: string, values: any) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_question_answer/${questionAnswerUuid}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getExamAttempts(accessToken: string, examUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam/${examUuid}/attempts?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
        
        return toJsonOrRaise(response)
    }

    static async getExamAttempt(accessToken: string, examAttemptUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt/${examAttemptUuid}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
        
        return toJsonOrRaise(response)
    }

    static async createExamAttempt(accessToken: string, exam_uuid: string, team_member_uuid: string, duration: number, status: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                exam_uuid,
                team_member_uuid,
                duration,
                status  
            })

        })
        return toJsonOrRaise(response)
    }

    static async updateExamAttempt(accessToken: string, examAttemptUuid: string, values: any) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt/${examAttemptUuid}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getExamAttemptQuestions(accessToken: string, examAttemptUuid: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt/${examAttemptUuid}/questions?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
        
        return toJsonOrRaise(response)
    }

    static async finishExamAttempt(accessToken: string, examAttemptUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt/${examAttemptUuid}/finish`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        return toJsonOrRaise(response)
    }

    static async getExamAttemptQuestion(accessToken: string, examAttemptQuestionUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt_question/${examAttemptQuestionUuid}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
        
        return toJsonOrRaise(response)
    }

    static async createExamAttemptQuestion(accessToken: string, exam_attempt_uuid: string, exam_question_uuid: string, exam_question_answer_uuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt_question`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                attempt_uuid: exam_attempt_uuid,
                question_uuid: exam_question_uuid,
                answer_uuid: exam_question_answer_uuid
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateExamAttemptQuestion(accessToken: string, examAttemptQuestionUuid: string, values: any) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt_question/${examAttemptQuestionUuid}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getExamAttemptQuestionByAttemptAndQuestion(accessToken: string, examAttemptUuid: string, examQuestionUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt/${examAttemptUuid}/question/${examQuestionUuid}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        return toJsonOrRaise(response)
    }

    static async getExamAttemptCompleted(accessToken: string, examAttemptUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_attempt/${examAttemptUuid}/completed`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        return toJsonOrRaise(response)
    }

    static async createExamQuestion(accessToken: string, examUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam/${examUuid}/questions`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        return toJsonOrRaise(response)
    }

    static async deleteExamQuestion(accessToken: string, examUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/exam_question/${examUuid}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        return raiseForStatus(response)
    }

   

    static async createConversation(accessToken: string, teamMemberUuid: string, personalityUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/conversation`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "team_member_uuid": teamMemberUuid,
                "personality_uuid": personalityUuid,
            })
        })
        return toJsonOrRaise(response)
    }

    static async getTeamMemberConversations(accessToken: string, teamMemberUuid: string,  page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/team_member/${teamMemberUuid}/conversations?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getConversationMessages(accessToken: string, conversationUuid: string,  page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/conversation/${conversationUuid}/messages?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async deleteConversation(accessToken: string, conversationUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/conversation/${conversationUuid}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        return raiseForStatus(response)
    }

    static async getConversationCode(accessToken: string, conversationUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/conversation/${conversationUuid}/code`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async createConversationCode(accessToken: string, conversationUuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/conversation/${conversationUuid}/code`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getConversationsByDate(accessToken: string, teamUuid: string, startDate: string, endDate: string) {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate
        })

        const response = await fetch(import.meta.env.VITE_URL + `/api/statistics/${teamUuid}/conversations?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getConversationsDistribution(accessToken: string, teamUuid: string, startDate: string, endDate: string) {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate
        })

        const response = await fetch(import.meta.env.VITE_URL + `/api/statistics/${teamUuid}/conversations/distribution?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getMembersRanking(accessToken: string, teamUuid: string, startDate: string, endDate: string) {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate
        })

        const response = await fetch(import.meta.env.VITE_URL + `/api/statistics/${teamUuid}/members/ranking?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getMarksDistribution(accessToken: string, teamUuid: string, startDate: string, endDate: string) {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate
        })

        const response = await fetch(import.meta.env.VITE_URL + `/api/statistics/${teamUuid}/marks/distribution?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async createSpeechByArticleContent(accessToken: string, articleUuid: string, azure_voice_uuid: string) {
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article/${articleUuid}/content/speech`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                azure_voice_uuid
            })
        })

        return raiseForStatus(response)
    }

    static async uploadAudioInArticle(accessToken: string, articleUuid: string, file: File): Promise<LessonArticle> {
        const formData = new FormData()
        formData.append('file', file, file.name)
        const response = await fetch(import.meta.env.VITE_URL + `/api/lesson_article/${articleUuid}/upload/audio`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }
}
export default Api
