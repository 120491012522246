//
//
//  Channels Data Table
//
//

import {Team} from "../../../interfaces.ts";
import {ActionIcon, Box, LoadingOverlay, Table} from "@mantine/core";
import {DataTableEmpty, DataTableLoading} from "../../../components/DataTable.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { IconEdit, IconTrash } from "@tabler/icons-react";


function TeamsDataTable(
    {teams, newTeams, loading=false, updating=false, onCreate, onSelection, onDelete}: 
    {
        teams: Team[], 
        newTeams: Team[],
        loading: boolean,
        updating: boolean,
        onCreate: () => void,
        onSelection: (team: Team) => void,
        onDelete: (team: Team) => void
    }
) {
    const [parent] = useAutoAnimate()

    if (loading) {
        return <DataTableLoading/>
    }

    if (!teams.length) {
        return <DataTableEmpty
            title="You don't have any team"
            subtitle=""
            action="Create your first Team"
            onAction={onCreate}
        />
    }


    return (
        <Box pos="relative">
            <LoadingOverlay visible={updating}/>
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Edit</Table.Th>
                        <Table.Th>Delete</Table.Th>
                    </Table.Tr>
                    </Table.Thead>
                <Table.Tbody ref={parent}>
                    {newTeams.map((team: Team) => {
                        return (
                            <Table.Tr key={team.uuid} style={{backgroundColor: "#F5FBEF"}}>
                                <Table.Td>
                                    {team.name}
                                </Table.Td>
                                <Table.Td>
                                    <ActionIcon
                                        key="edit"
                                        size="sm"
                                        variant="subtle"
                                        color="blue"
                                        onClick={() => onSelection(team)}
                                    >
                                        <IconEdit size={16} />
                                    </ActionIcon>
                                </Table.Td>
                                <Table.Td>
                                    <ActionIcon
                                        key="delete"
                                        size="sm"
                                        variant="subtle"
                                        color="red"
                                        onClick={() => onDelete(team)}
                                    >
                                        <IconTrash size={16} />
                                    </ActionIcon>
                                </Table.Td>
                            </Table.Tr>
                        )
                    })}

                    {teams.map((team: Team) => {
                        return (
                            <Table.Tr key={team.uuid}>
                                <Table.Td>
                                    {team.name}
                                </Table.Td>
                                <Table.Td>
                                    <ActionIcon
                                        key="edit"
                                        size="sm"
                                        variant="subtle"
                                        color="blue"
                                        onClick={() => onSelection(team)}
                                    >
                                        <IconEdit size={16} />
                                    </ActionIcon>
                                </Table.Td>
                                <Table.Td>
                                    <ActionIcon
                                        key="delete"
                                        size="sm"
                                        variant="subtle"
                                        color="red"
                                        onClick={() => onDelete(team)}
                                    >
                                        <IconTrash size={16} />
                                    </ActionIcon>
                                </Table.Td>
                            </Table.Tr>
                        )
                    })}
                </Table.Tbody>
            </Table>
        </Box>
    )
}

export default TeamsDataTable