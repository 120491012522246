//
//
//  New Article Start
//
//

import {Flex, SimpleGrid, Title, Text} from "@mantine/core";
import {IconFileMusic, IconSparkles} from "@tabler/icons-react";
import classes from "./NewArticleStart.module.css"

function NewAudioStart({onSubmit}: {onSubmit: (type: string) => void}) {
    return (
        <Flex direction="column" align="center" mb={12} mr={12} ml={12}>
            <Title mb={20} order={5}>How would you like to start?</Title>
            <SimpleGrid cols={3} spacing="xl">
                <Flex direction="column" align="center">
                    <button className={classes.templateButton} onClick={() => onSubmit("tts")}>
                        <IconSparkles size={42}  color="#F8AA12"/>
                    </button>
                    <Title mt={8} order={6}>Audio Generation</Title>
                    <Text ta="center" size="sm" c="dimmed">Generate audio file</Text>
                </Flex>
                <Flex direction="column" align="center">
                    <button className={classes.templateButton} onClick={() => onSubmit("files")}>
                        <IconFileMusic size={42} color="#51CF66"/>
                    </button>
                    <Title mt={8} order={6}>Use file</Title>
                    <Text ta="center" size="sm" c="dimmed">Upload existing audio file</Text>
                </Flex>
            </SimpleGrid>
        </Flex>
    )
}

export default NewAudioStart