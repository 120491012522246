//
//
//  Bar Chart
//
//

import { BarChart, Card, Metric } from "@tremor/react";
import { Flex, LoadingOverlay, Title } from "@mantine/core";


function HorizontalBarChart({
    data,
    categories,
    title,
    loading
}: {
    data: Record<string, any>[];
    categories: string[];
    title?: string | null;
    loading: boolean;
}) {
    return (
        <Card>
            <LoadingOverlay visible={loading} />
            <Flex justify="space-between">
                <Flex direction="column" gap={10}>
                    <Metric className="text-right"></Metric>
                </Flex>
            </Flex>

            {title != null && (
                <Flex justify="center">
                    <Title order={3}>{title}</Title>
                </Flex>
            )}

            <BarChart
                className="h-72 mt-4"
                data={data}
                index="xValue"
                stack={true}
                categories={categories}
                autoMinValue={true}
            />
        </Card>
    );
}

export default HorizontalBarChart;