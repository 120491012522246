//
//
//  New Lesson Modal
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, Textarea, TextInput} from "@mantine/core";

function Create({onSubmit, onCancel}: {onSubmit: (values: any) => void, onCancel: () => void}) {
    const form = useForm({
        initialValues: {
            title: "",
            description: ""
        }
    })

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <TextInput
                required
                withAsterisk
                label="Title"
                placeholder="Super duper name"
                {...form.getInputProps("title")}
            />
            <Textarea
                mt={12}
                label="Description"
                {...form.getInputProps("description")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </Flex>
        </form>
    )
}

export default Create