//
//
//  Require Logged In
//
//

import {Outlet} from "react-router-dom"
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {LoadingOverlay} from "@mantine/core";
import {SocketProvider} from "../contexts/SocketContext.tsx";


 function RequireLoggedIn() {
     const { isLoading} = useAuth0()

     if (isLoading) {
         return (
             <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval"}}/>
         )
     }

     const Component = withAuthenticationRequired(Outlet, {
         returnTo: () => window.location.hash.substring(1),
         onRedirecting: () => {
             return (
                 <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
             )
         }
     })
     return (
        <>
            <SocketProvider>
                <Component/>
            </SocketProvider>
        </>
    )
 }

export default RequireLoggedIn
