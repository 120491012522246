//
//
//  Not Found
//
//

import { Container, Title, Text, Button, Group, Flex, Image } from '@mantine/core';
import classes from './index.module.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../assets/logo-no-text.svg";


function NotFound() {
  const navigate = useNavigate()

  return (
    <Container className={classes.root}  my={50}>
      <Flex justify="center"  align={'center'} style={{height: "100px"}} mb={50}>
        <Link to="/">
            <Image src={logo} h={100} w="auto" fit="contain" />
        </Link>
      </Flex>
      <div className={classes.label}>404</div>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title size="h3" className={classes.title} mb={10}>{"Nothing to see here"}</Title>
          <Flex justify={'center'} mb={10}>
            <Text c="dimmed" size="sm" ta="center" className={classes.description}>
                {"The page you are trying to open does not exist."}
            </Text>
          </Flex>
          <Group justify="center">
            <Button mt={50} onClick={() => navigate('/')}>{"Take me back to home page"}</Button>
          </Group>
        </div>
      </div>
    </Container>
  );
}

export default NotFound