//
//
//  App
//
//

import "./App.css"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import RequireLoggedIn from "./components/RequireLoggedIn.tsx";
import { Auth0Provider } from "@auth0/auth0-react";
import Home from "./routes/Home/Home.tsx";
import Profile from "./routes/Profile/Profile.tsx";
import Overview from "./routes/Overview/General.tsx";
import Conversations from "./routes/Conversations/Index.tsx";
import Lessons from "./routes/Lessons/Index.tsx";
import Admin from "./routes/Admin/Admin.tsx";
import AdminTeamsList from './routes/Admin/Teams/List.tsx';
import AdminTeamsEdit from './routes/Admin/Teams/Edit.tsx';
import LessonArticle from "./routes/Lessons/Article/Article.tsx";
import Exams from "./routes/Exams/Index.tsx";
import ExamEditor from "./routes/Exams/Components/ExamEditor.tsx";
import NotFound from "./routes/NotFound/index.tsx";
import ExamAttempt from "./routes/Exams/Attempt/Index.tsx";
import RequireTeamProvider from "./components/RequireTeamProvider.tsx";
import ExamPreview from "./routes/Exams/Preview/Index.tsx";


function App() {

    const navigate = useNavigate()
    const onRedirectCallback = (appState: any) => {
        navigate((appState && appState.returnTo) || window.location.pathname)
    }
    return (
        <>
            <ModalsProvider labels={{ confirm: "Submit", cancel: "Cancel" }}>
                    <Auth0Provider
                        domain={import.meta.env.VITE_AUTH0_DOMAIN}
                        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                            audience: import.meta.env.VITE_AUTH0_AUDIENCE
                        }}
                        onRedirectCallback={onRedirectCallback}
                        cacheLocation="localstorage"
                    >   
                        <Routes>
                            <Route element={<RequireTeamProvider />}>
                                <Route element={<RequireLoggedIn />}>
                                    <Route path="/" Component={Home}>
                                        <Route index element={<Navigate to="overview" />}/>
                                        <Route path="/overview" Component={Overview}/>
                                        <Route path="/conversations">
                                            <Route index Component={Conversations}/>
                                        </Route>
                                        <Route path="/lessons">
                                            <Route index Component={Lessons}/>
                                            <Route path="articles/:articleUuid" Component={LessonArticle}/>
                                        </Route>
                                        <Route path="/exams/">
                                            <Route index Component={Exams}/>
                                            <Route path="edit/:examUuid" Component={ExamEditor}/>
                                            <Route path="take/:examUuid" Component={ExamAttempt}/>
                                            <Route path="preview/:examAttemptUuid" Component={ExamPreview}/>
                                        </Route>
                                        <Route path="/personalities" Component={Profile}/>
                                    </Route>
                                </Route>
                            </Route>

                            <Route element={<RequireLoggedIn />}>
                                <Route path="*" Component={NotFound}/>
                                <Route path="/admin" Component={Admin}>
                                    <Route index element={<Navigate to="teams" />}></Route>
                                    <Route path="teams" Component={AdminTeamsList} />
                                    <Route path="teams/:teamUuid" Component={AdminTeamsEdit} />
                                </Route>
                            </Route>
                        </Routes>
                    </Auth0Provider>
                <Notifications />
            </ModalsProvider>
        </>
    )
}

export default App
