//import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import Create from "./Create";
import Api from "../../api";
import { modals } from "@mantine/modals";
import { DataTable } from "mantine-datatable";
import DashboardHeader from "../../components/DashboardHeader";
import classes from "./Index.module.css";
import { IconMoodSad, IconVocabulary } from "@tabler/icons-react";
import Lesson from "./Lesson";
import { useTeam } from "../../contexts/TeamContext";
import { useAuth0 } from "@auth0/auth0-react";
import { LessonInterface } from "../../interfaces";

function Lessons() {
  const { getAccessTokenSilently } = useAuth0();
  const {team, role} = useTeam()
  const [lessons, setLessons] = useState<LessonInterface[]>([]);
  const pageLength = 20;
  const [lessonsLoading, setLessonsLoading] = useState(false);
  const [page] = useState(1);
  const [selectedLesson, setSelectedLesson] = useState<LessonInterface>();

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        }
   })
   return accessToken
}

  useEffect(() => {
    fetchLessons();
}, [getAccessTokenSilently, team])


async function createLesson(name: string, description: string) {
    setLessonsLoading(true)
    try {
        Api.createLesson(await getAccessToken(), name, description, team!.uuid)
            .then((response) => {
                setLessons([...lessons, response])
            })
            .catch((error) => {
                console.error(error)
            }).finally(() => {
                setLessonsLoading(false)
                modals.closeAll()
            })
    } catch (error) {
        console.error(error)
    }
}


async function fetchLessons() {
    setLessonsLoading(true)
    try {
        Api.getTeamLessons(await getAccessToken(), team!.uuid, page, pageLength)
        .then((pageLessons) => {
            let lessonToSelect = null
            const cacheSelectedLessonUudText = sessionStorage.getItem("dashboard-trainer-selected-lesson")
            if (cacheSelectedLessonUudText !== null) {
                lessonToSelect = pageLessons.items.find((lesson: LessonInterface) => {
                    const isMatch = lesson.uuid === cacheSelectedLessonUudText;
                    return isMatch;
                });
            }
            if (lessonToSelect == null && selectedLesson == null && pageLessons.items.length > 0) {
                lessonToSelect = pageLessons.items[0]
            }

            setSelectedLesson(lessonToSelect)
            setLessons(pageLessons.items)
            setLessonsLoading(false)
        })
        .catch((error) => {
            console.error(error)
        }).finally(() => {
            
            modals.closeAll()
        })
    } catch (error) {
        console.error(error)
    }
}
  const createLessonModal = () => {
    modals.open({
        title: "Create Lesson",
        children: (
            <Create
                onSubmit={(values) => {
                    createLesson(values.title, values.description)
                }}
                onCancel={() => {
                    modals.closeAll()
                }}
            />
        )
    })
}

function onSelectLesson(newLesson: any) {
    setSelectedLesson(newLesson)
    sessionStorage.setItem("dashboard-trainer-selected-lesson", newLesson.uuid)
}

const header = (
    <DashboardHeader
      rightSection={role === 'EDITOR' ? <Button onClick={createLessonModal}>Create lesson</Button> : undefined}
      title="Lessons"
    />
  );


  return (
    <>
            {header}
            <Flex>
                <nav className={classes.nav}>
                    <DataTable
                        noHeader
                        fetching={lessonsLoading}
                        records={lessons}
                        withRowBorders={false}
                        columns={[
                            {
                                accessor: "title",
                                ellipsis: true,
                                width: 300,
                                render: (lesson) => {
                                    return (
                                        <Button
                                            size="xs"
                                            onClick={() => onSelectLesson(lesson)}
                                            variant={lesson.uuid == selectedLesson?.uuid ? "filled" : "subtle"}
                                            key={lesson.uuid}
                                            style={{width: "100%"}}
                                            classNames={{inner: classes.navButton}}
                                        >
                                            <Flex gap={10} align="center" className={classes.lessonNameContainer}>
                                                <IconVocabulary size={16}/>
                                                <Text size="sm" td={lesson.disabled ? "line-through" : undefined} className={classes.lessonName}>{lesson.name}</Text>
                                            </Flex>
                                        </Button>
                                    )
                                }
                            }
                        ]}
                        className={classes.navTable}
                        minHeight={lessons.length > 0 ? 0 : 300}
                        emptyState={
                            <>
                                <Flex justify="center" align="center" direction="column" gap={20}>
                                    <IconMoodSad size={60}/>
                                    <Title order={4}>No Lessons found</Title>
                                    <Button onClick={createLessonModal}>Create Lesson</Button>
                                </Flex>
                            </>
                        }
                    />
                </nav>
                <Flex ml={20} mr={20} style={{width: "100%"}}>
                    {selectedLesson &&
                        <Lesson
                            lesson={selectedLesson}
                            onUpdate={(newLesson: LessonInterface) => {
                                setLessons(lessons.map(lesson => lesson.uuid == newLesson.uuid ? newLesson : lesson))
                                if (selectedLesson.uuid == newLesson.uuid) {
                                    setSelectedLesson(newLesson)
                                }
                            }}
                            onDelete={(deletedLesson: any) => {
                                setLessons(lessons.filter(lesson => lesson.uuid != deletedLesson.uuid))
                                setSelectedLesson(undefined)
                            }}
                        />
                    }
                </Flex>
            </Flex>
        </>
  );
}

export default Lessons;
