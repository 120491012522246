import {
  Checkbox,
  Flex,
  Grid,
  Paper,
  Skeleton,
} from "@mantine/core";
import Api from "../../../api";
import {
  ExamAnswerInterface,
  ExamQuestionInterface,
} from "../../../interfaces";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import EditableText from "../../../components/EditableText";

function Question({
  question,
  disabled
}: {
  question: ExamQuestionInterface;
  disabled: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [answers, setAnswers] = useState<ExamAnswerInterface[]>();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });

    return accessToken;
  }

  async function fetchAnswers() {
    if (!question) { 
      return;
    }
    setIsLoading(true);
    Api.getExamQuestionAnswers(await getAccessToken(), question.uuid, 1, 25).then(
      (pageAnswers) => {
        setAnswers(pageAnswers.items);
        setIsLoading(false);
      }
    );
  }

  async function handleChangeAnswer(newText: string, index: number) {
    if (!answers) {
      return;
    }
    const newAnswers = [...answers];
    newAnswers[index].text = newText;
    setAnswers(newAnswers);
    Api.updateExamAnswer(await getAccessToken(), newAnswers[index].uuid, newAnswers[index]);
  }

  async function handleChangeCorrectAnswer(index: number, previousCorrectIndex: number) {
    if (!answers) {
      return;
    }
  
    const newAnswers = [...answers];
    setIsSaving(true);
  
    try {
      if (previousCorrectIndex !== -1) {
        newAnswers[previousCorrectIndex].is_correct = false;
        newAnswers[index].is_correct = true;
        setAnswers(newAnswers);
        await Api.updateExamAnswer(await getAccessToken(), newAnswers[previousCorrectIndex].uuid, newAnswers[previousCorrectIndex]);
      }
      
      await Api.updateExamAnswer(await getAccessToken(), newAnswers[index].uuid, newAnswers[index]);
  
    } catch (error) {
      console.error("Failed to update exam answer", error);
    } finally {
      setIsSaving(false);
    }
  }
  

  useEffect(() => {
    if (!question) {
      return;
    }

    fetchAnswers();
  }, [question]);

  return (
    <Paper p={"xl"} radius={"sm"} shadow="sm">
      <Flex direction={"column"} m={10} gap={10}>
        {isLoading ? (
            <React.Fragment>
                <Skeleton height="20px" width="100%" />
                <Skeleton height="20px" width="100%" />
                <Skeleton height="20px" width="100%" />
            </React.Fragment>
        ) : ''}
          {answers?.map((answer, index) => (
            <Grid columns={12} w={'full'}>
                <Grid.Col span={11}>
                <EditableText
                    initialText={answer.text}
                    onSave={(newText) => handleChangeAnswer(newText, index)}
                    disabled={disabled}
                    />
                </Grid.Col> 
                <Grid.Col span={1}>
                    <Flex justify={"center"}>
                    <Checkbox 
                        checked={answer.is_correct}
                        disabled={isSaving  || disabled}
                        onChange={() => handleChangeCorrectAnswer(index, answers.findIndex(a => a.is_correct))} />
                    </Flex>
                </Grid.Col>
            </Grid>
          ))}
      </Flex>
    </Paper>
  );
}

export default Question;
