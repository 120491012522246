//
//
//  New Article Modal
//
//

import {useForm} from "@mantine/form";
import {Button, FileInput, Flex} from "@mantine/core";
import {useState} from "react";

function AudioFileForm({onSubmit, onCancel}: {onSubmit: (values: any) => void, onCancel: () => void}) {
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            title: "",
            file: "",
            startType: "files"
        }
    })

    function onSubmitInner(values: any)  {
        setLoading(true)
        onSubmit(values)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
            <FileInput
                mt={12}
                required
                withAsterisk
                accept="audio/mpeg, audio/wav, audio/ogg, audio/mp4"
                label="Audio File"
                multiple={false}
                {...form.getInputProps("file")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit" loading={loading}>Submit</Button>
            </Flex>
        </form>
    )
}

export default AudioFileForm
