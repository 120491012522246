import { useAuth0 } from "@auth0/auth0-react";

import { Button, Group, LoadingOverlay, Modal, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";

import { useApiErrorHandler } from "../../../../hooks.ts";
import Api from "../../../../api.ts";
import { TeamMember } from "../../../../interfaces.ts";


interface TeamMemberForm {
    role: string
}

function TeamMemberEditModal({member, opened, setOpened}: {
    member: TeamMember,
    opened: boolean,
    setOpened: (open: boolean) => void
}) {
    const { getAccessTokenSilently } = useAuth0()
    const handleError = useApiErrorHandler()
    const [editLoading, setEditLoading] = useState(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    const teamMemberForm = useForm<TeamMemberForm>({
        initialValues: {
            role: member.role.toUpperCase()
        }
    })


    function handleClose() {
        setOpened(false)
        teamMemberForm.reset()
    }

    useEffect(() => {
        teamMemberForm.setValues({
            role: member.role.toUpperCase()
        })
    }, [member])
        


    async function onSubmitEdit(values: TeamMemberForm) {
        setEditLoading(true)
        Api.updateTeamMember(await getAccessToken(), member.uuid, values)
            .then(() => {
                setEditLoading(false)
                teamMemberForm.reset()
                handleClose()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setEditLoading(false)
            })
    }
    
    return (
        <Modal opened={opened} onClose={() => handleClose()} size="65%" title={"Edit member"} >
            <LoadingOverlay visible={editLoading}/>

            <form onSubmit={teamMemberForm.onSubmit(onSubmitEdit)}>
                <Select
                    label="Role"
                    allowDeselect={false}
                    data={[{"label": "Student", "value": "VIEWER"}, {"label": "Teacher", "value": "EDITOR"}]}
                    {...teamMemberForm.getInputProps("role")}
                />
                
                <Group justify="center" mt={48}>
                    <Button type="submit">Save</Button>
                </Group>
            </form>

        </Modal>
    )
}

export default TeamMemberEditModal