import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
//import { Socket } from "socket.io-client";

import { Button, LoadingOverlay, TextInput, Title, rem } from "@mantine/core"
import { useForm } from "@mantine/form";

import { Team } from "../../../interfaces";
import { useApiErrorHandler } from "../../../hooks.ts";
import Api from "../../../api.ts";
import TeamMembersTable from "./Members/List.tsx";


interface TeamForm {
    name: string
}


function AdminTeamEdit () {
    const { getAccessTokenSilently } = useAuth0()
    //const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const { teamUuid } = useParams()
    const [team, setTeam] = useState<Team | null>(null)
    const [updateLoading, setUpdateLoading] = useState(false)

    const teamForm = useForm<TeamForm>()

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function getTeam() {
        if (teamUuid != null) {
            Api.getTeam(await getAccessToken(), teamUuid)
                .then(serverTeam => {
                    setTeam(serverTeam)

                    if (serverTeam != null) {
                        teamForm.setFieldValue("name", serverTeam.name)
                    }
                }).catch((err) => {
                    console.error(err);
                    handleError(err)
                })
        }
    }

    

    useEffect(() => {
        getTeam()
    }, [teamUuid, /*socket*/])


    


    async function onUpdateTeam(values: any) {
        const data = {...values}
        setUpdateLoading(true)

        if (team) {
            Api.updateTeam(await getAccessToken(), team.uuid, data)
                .then(() => {
                    setUpdateLoading(false)
                }).catch((err) => {
                    console.error(err);
                    handleError(err)
                    setUpdateLoading(false)
                })
        }
    }


    if (team == null) {
        return (
            <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval"}}/>
        )
    } else {
        return (
            <>
                <Title size="h1" mb={rem(20)} mt={rem(50)}>{"Edit team"}</Title>
    
                <form onSubmit={teamForm.onSubmit(onUpdateTeam)}>
                    <TextInput
                        label="Name"
                        mb="md"
                        {...teamForm.getInputProps("name")}
                    />
    
                    <Button mt="md" type="submit" loading={updateLoading}>Save</Button>
                </form>

                <Title size="h3" mb={rem(20)} mt={rem(50)}>{"Team members"}</Title>

                <TeamMembersTable team={team}/>
            </>
        )
    }


    
}

export default AdminTeamEdit