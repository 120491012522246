//
//
//  New Article Modal
//
//


import NewArticleStart from "./NewArticleStart.tsx";
import {modals} from "@mantine/modals";
import NewArticleScratchForm from "../../../components/LessonScratchForm.tsx";
import NewArticleFileForm from "../../../components/LessonForm.tsx";


function NewArticleModal({onSubmit}: {onSubmit: (values: any) => void}) {
    return (
        <NewArticleStart
            onSubmit={(startType) => {
                if (startType === "blank") {
                    modals.open({
                        title: "Add article from scratch",
                        children: (
                            <NewArticleScratchForm
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else if (startType === "files") {
                    modals.open({
                        title: "Add article from files",
                        children: (
                            <NewArticleFileForm
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else {
                    throw new Error("Invalid start type")
                }
            }}
        />
    )
}

export default NewArticleModal