//
//
//  Logout hook
//
//

import {useAuth0} from "@auth0/auth0-react";

function useLogout() {
    const {logout} = useAuth0()

    return () => {
        localStorage.removeItem("teamId")
        return logout()
    }
}

export default useLogout