import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "@mantine/core/styles.css";
import '@mantine/tiptap/styles.css'
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.layer.css";
import '@mantine/notifications/styles.css';
import "mantine-datatable/styles.layer.css";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "maia-chat-widget": any;
    }
  }
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MantineProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);
