//
//
//  Profile Menu
//
//

import {Avatar, Flex, Menu, Text} from "@mantine/core";
import classes from "./ProfileMenu.module.css";
import {
    IconBrandCodesandbox,
    IconChevronRight,
    IconEye,
    IconLogout,
    IconShieldLock,
    IconSwitchHorizontal
} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import useLogout from "../hooks/logout.ts";
import useAdmin from "../hooks/admin.ts";
import { useTeam } from "../contexts/TeamContext.tsx";
import SwitchTeamsModal from "./SwitchTeamsModal.tsx";
import { useState } from "react";
import { Team } from "../interfaces.ts";

function ProfileMenu({includeDashboard, includeAdmin, onChangeView, currentUserRole}: {includeDashboard?: boolean, includeAdmin?: boolean, onChangeView?: () => void, currentUserRole?: string}) {
    
    const {permissionLevel, team, setTeam} = useTeam()
    const isAdmin = useAdmin()
    const {user} = useAuth0()
    const logout = useLogout()

    const [switchTeamModalOpen, setSwitchTeamModalOpen] = useState(false)
    const notCurrentView = currentUserRole === "EDITOR" ? "Student" : "Teacher"

    function changeTeam(team: Team) {
        setTeam(team)
        setSwitchTeamModalOpen(false)
    }


    return (
        <>
            <Menu shadow="md" width={230}>
                <Menu.Target>
                    <button className={classes.navbarBtn}>
                        <Flex align="center" justify="space-between" style={{width: "100%"}}>
                            <Flex gap={10} align="center">
                                <Avatar variant="white" size="md" radius="xl" src={user?.picture}/>
                                <span>{user?.name}</span>
                            </Flex>
                            <IconChevronRight size={16} />
                        </Flex>
                    </button>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item leftSection={<IconSwitchHorizontal size={14}/>} onClick={()=>setSwitchTeamModalOpen(true)}>
                        <Flex direction="column">
                            <Text fw={500} size={"sm"}>{team?.name}</Text>
                            <Text size="sm" c="dimmed">Switch team</Text>
                        </Flex>
                    </Menu.Item>

                    {permissionLevel === "EDITOR" && 
                        <Menu.Item leftSection={<IconEye size={14}/>} onClick={onChangeView}>
                            Go to {notCurrentView} view
                        </Menu.Item>
                    }

                    {includeDashboard && <>
                            <Menu.Divider />
                            <Menu.Item leftSection={<IconBrandCodesandbox size={14} />} component={Link} to="/dashboard">
                                Dashboard
                            </Menu.Item>
                        </>
                    }
                    {includeAdmin && isAdmin && 
                        <>
                            <Menu.Divider />
                            <Menu.Item leftSection={<IconShieldLock size={14} />} component={Link} to="/admin">
                                Admin
                            </Menu.Item>
                        </>
                    }
                    <Menu.Divider />
                    <Menu.Item leftSection={<IconLogout size={14} />} onClick={logout}>
                        Logout
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <SwitchTeamsModal 
                opened={switchTeamModalOpen} 
                onClose={() => setSwitchTeamModalOpen(false)} 
                onSelect={changeTeam} 
            />
        </>
    )
}

export default ProfileMenu