import {
    Container,
    Flex,
    Text,
    Pagination,
    rem
} from "@mantine/core";

interface PaginationControlProps {
    totalElements: number
    page: number
    pageLength: number
    onChange: React.Dispatch<React.SetStateAction<number>>
}


function PaginationControl(props: PaginationControlProps) {
    const {totalElements, page, pageLength, onChange} = props
    const total = Math.ceil(totalElements / pageLength)
    const min = ((page-1) * pageLength) + 1
    const max = Math.min(page * pageLength, totalElements)

    return (
        <>
        {totalElements > pageLength ? 
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    {min !== max ? <Text mb="xs">{min}-{max} ({"out of"} {totalElements})</Text> :
                                <Text mb="xs">{min} ({"out of"} {totalElements})</Text>}
                    <Pagination
                        total={total}
                        value={page}
                        onChange={onChange}
                        mb="xs"
                    />
                </Flex>
                
            </Container> : <></>
        }
        </>
    )
}

export default PaginationControl