//
//
//  New Exam Modal
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, NumberInput, Select, TextInput} from "@mantine/core";
import { LessonInterface } from "../../interfaces";


function Create({lessons, onSubmit, onCancel}: {lessons: LessonInterface[], onSubmit: (values: any) => void, onCancel: () => void}) {


    const form = useForm({
        initialValues: {
            name: "",
            time_limit: "",
            pool_size: "",
            selectedLesson: ""
        }
    })

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            
            <TextInput
                required
                withAsterisk
                label="Title"
                placeholder="Super duper name"
                {...form.getInputProps("name")}
            />
            <Select
                label="Select the lesson"
                data={lessons.map((lesson) => ({ value: lesson.uuid, label: lesson.name }))}
                onChange={(value) => {
                    const lesson = lessons.find((lesson) => lesson.uuid === value);
                    if (lesson) {
                      form.setFieldValue("selectedLesson", lesson.uuid);
                    }
                  }}
                  value={form.values.selectedLesson}
                />
            <NumberInput
                required
                label="Time limit"
                placeholder="10"
                withAsterisk
                description="Time limit in minutes"
                {...form.getInputProps("time_limit")}
            />
            <NumberInput
                required
                label="Questions pool size"
                placeholder="10"
                min={10}
                withAsterisk
                description="Size of the questions pool"
                {...form.getInputProps("pool_size")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </Flex>
        </form>
    )
}

export default Create