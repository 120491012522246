import {
    Button,
    Flex,
    Container,
    Grid,
    Paper,
    Text,
    Title,
    Stack,
    LoadingOverlay,
    Alert,
    CheckIcon,
} from "@mantine/core";
import DashboardHeader from "../../../components/DashboardHeader";
import React, { useEffect, useRef, useState } from "react";
import {
    ExamAttemptInterface,
    ExamAttemptQuestionInterface,
    ExamInterface,
} from "../../../interfaces";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../api";
import { intToHoursMinutesSeconds } from "../../../utils";
import QuestionAttempt from "../Components/QuestionAttempt";
import { IconArrowLeft, IconArrowRight, IconInfoCircle } from "@tabler/icons-react";
import { useTeam } from "../../../contexts/TeamContext";

const PAGE_SIZE = 25;

function ExamAttempt() {


    const navigate = useNavigate()
    //loaders
    const [loading, setLoading] = useState(true);
    const [loadingStartExam, setLoadingStartExam] = useState(false);
    const [loadingFinishExam, setLoadingFinishExam] = useState(false);

    const { teamMemberUuid } = useTeam()
    const timerIdRef = useRef<number | null>(null);
    const [exam, setExam] = useState<ExamInterface>();
    const [examQuestions, setExamQuestions] = useState<ExamAttemptQuestionInterface[]>();
    const [questionSelected, setQuestionSelected] =
        useState<ExamAttemptQuestionInterface>();
    
    const [examAttempt, setExamAttempt] = useState<ExamAttemptInterface>();
    const { getAccessTokenSilently } = useAuth0();
    const [time, setTime] = useState<string>();
    const [oneMinuteLeft, setOneMinuteLeft] = useState(false);
    const [timerActive, setTimerActive] = useState(false);
    const params = useParams();


    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            },
        });

        return accessToken;
    }

    async function fetchExam() {
        if (!params.examUuid) {
            return;
        }

        const accessToken = await getAccessToken(); // Ensure this call is correct and awaited outside of parameter passing

        Api.getExam(accessToken, params.examUuid).then((exam) => {
            setExam(exam);
            Api.getExamAttempts(accessToken, exam.uuid, 1, PAGE_SIZE).then(
                (pageAttempts) => {
                    const examAttempt = pageAttempts.items[0];
                    setExamAttempt(examAttempt);
                    if (
                        examAttempt &&
                        examAttempt.status === "ONGOING" &&
                        examAttempt.team_member_uuid ===
                        teamMemberUuid
                    ) {
                        localStorage.setItem("examAttemptUuid", examAttempt.uuid);
                        const started = new Date(examAttempt.created_at);
                        const now = new Date();
                        const utcOffsetInMs = now.getTimezoneOffset() * 60000;
                        const nowUTC = new Date(now.getTime() + utcOffsetInMs);
                        const differenceInSeconds = Math.floor((+nowUTC - +started) / 1000);
                        const remainingTimeInSeconds = Math.max(0, exam.time_limit * 60 - differenceInSeconds)
                        setTime(intToHoursMinutesSeconds(remainingTimeInSeconds));
                        setTimerActive(true);
                    } else {
                        setLoading(false);
                        setTime(intToHoursMinutesSeconds(exam.time_limit * 60));
                        if (examAttempt && examAttempt.status === 'COMPLETED') {
                            setTimerActive(false);
                            setTime('00:00:00');
                        }

                    }
                    setLoading(false);
                }
            );
        });
    }

    async function fetchExamQuestions() {
        if (!examAttempt) {
            return;
        }
        Api.getExamAttemptQuestions(
            await getAccessToken(),
            examAttempt.uuid,
            1,
            PAGE_SIZE
        ).then((pageQuestions) => {
            setExamQuestions(pageQuestions.items);
            setQuestionSelected(pageQuestions.items[0]);
        });
    }

    async function finishExamAttempt() {
        if (!examAttempt) {
            return;
        }
        setLoadingFinishExam(true);
        Api.finishExamAttempt(await getAccessToken(), examAttempt.uuid).then((attempt) => {
            setExamAttempt(attempt);
            setLoadingFinishExam(false);
            setTimerActive(false);
            setTime('00:00:00');
        }).then(() => {
            navigate("/exams/preview/" + examAttempt.uuid);
        });
    }


    async function startExam() {
        if (!exam || exam.status !== "DEPLOYED") {
            return;
        }
        setLoadingStartExam(true);
        try {
            const accessToken = await getAccessToken();
            await Api.createExamAttempt(
                accessToken,
                exam.uuid,
                teamMemberUuid,
                0,
                "ONGOING"
            ).then((attempt) => {
                setExamAttempt(attempt);
                localStorage.setItem("examAttemptUuid", attempt.uuid);
                setTimerActive(true);
                setLoadingStartExam(false);
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchExam();
    }, [params]);

    useEffect(() => {
        if (examAttempt) {
            fetchExamQuestions();
        }
    }, [examAttempt]);

    useEffect(() => {
        let intervalId: any = null;
    
        if (timerActive && !intervalId) {
            intervalId = setInterval(() => {
                setTime((prevTime) => {
                    
                    const totalSeconds = (prevTime ?? '00:00:00').split(":").reduce((acc, time) => 60 * acc + Number(time), 0) - 1;
                    if (totalSeconds < 60) {
                        setOneMinuteLeft(true);
                    }
                    if (totalSeconds <= 0) {
                        clearInterval(intervalId as any);
                        setTimerActive(false);
                        finishExamAttempt();
                        return "00:00:00";
                    } else {
                        
                        return intToHoursMinutesSeconds(totalSeconds);
                    }
                });
            }, 1000);
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [timerActive]);
    

    const header = exam ? (
        <DashboardHeader
          title={exam.name}
          rightSection={
            examAttempt && examAttempt.status === 'ONGOING' ? (
              <Flex gap={12}>
                <Button loading={loadingFinishExam} onClick={() => finishExamAttempt()}>
                  Finish Exam
                </Button>
              </Flex>
            ) : null
          }
        />
      ) : null;
      

    const goToPrev = () => {
        if (!examQuestions) {
            return;
        }
        const currentIndex = examQuestions.findIndex(
            (q) => q.uuid === questionSelected?.uuid
        );
        if (currentIndex > 0) {
            setQuestionSelected(examQuestions[currentIndex - 1]);
        }
    };



    const goToNext = () => {
        if (!examQuestions) {
            return;
        }
        const currentIndex = examQuestions.findIndex(
            (q) => q.uuid === questionSelected?.uuid
        );
        if (currentIndex < examQuestions.length - 1) {
            setQuestionSelected(examQuestions[currentIndex + 1]);
        }
    };

    useEffect(() => {
        if (exam?.status === "COMPLETED" && timerIdRef.current) {
            clearInterval(timerIdRef.current as unknown as number);
            setTimerActive(false);
            setTime('00:00:00');
        }
    
        // Optional: Clean up on component unmount
        return () => {
            if (timerIdRef.current) {
                clearInterval(timerIdRef.current as unknown as number);
            }
        };
    }, [exam?.status]);

    if (loading) {
        return <LoadingOverlay visible />;
    }
    if (exam) {
        return (
            <>
                {header}
                <Grid grow gutter="xs">
                    <Grid.Col span={3}>
                        <Flex
                            p={50}
                            gap={20}
                            direction={"column"}
                            justify={"center"}
                            align={"center"}
                        >
                            <Paper p="md" shadow="sm" w={"100%"}>
                                <Title my={"sm"} size="h4" c={"blue"}>
                                    Exam
                                </Title>
                                <Text fw={500} size="md">
                                    Name: {exam.name}
                                </Text>
                            </Paper>
                            <Paper p="md" shadow="sm" w={"100%"}>
                                <Title my={"sm"} size="h4" c={"blue"}>
                                    Time
                                </Title>
                                <Text fw={500} size="md" c={oneMinuteLeft ? "red" : "black"}>
                                    {time}
                                </Text>
                            </Paper>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <Container>
                            <Flex p={50} direction={"column"} gap={10}>
                                {examAttempt ? (
                                    examAttempt.status === 'ONGOING' && questionSelected ? (
                                        <React.Fragment>
                                            <Flex justify={"space-between"} direction={"row"}>
                                                <Button
                                                    onClick={goToPrev}
                                                    variant="subtle"
                                                    disabled={examQuestions?.[0].uuid === questionSelected.uuid}
                                                    leftSection={<IconArrowLeft size={16} />}
                                                >
                                                    Previous question
                                                </Button>
                                                <Text>{examQuestions!.findIndex(q => q.uuid === questionSelected.uuid) + 1} / {examQuestions!.length}</Text>
                                                <Button
                                                    onClick={goToNext}
                                                    variant="subtle"
                                                    disabled={examQuestions?.[examQuestions.length - 1].uuid === questionSelected.uuid}
                                                    rightSection={<IconArrowRight size={16} />}
                                                >
                                                    Next question
                                                </Button>
                                            </Flex>
                                            <QuestionAttempt key={`${examAttempt.uuid}_${questionSelected.uuid}`} question={questionSelected} />
                                        </React.Fragment>
                                    ) : examAttempt.status === 'COMPLETED' && examQuestions ? (
                                        <Alert variant="light" color="green" title="Exam Completed" icon={<CheckIcon />}>
                                            Your final score is {examAttempt.mark} / 10
                                        </Alert>
                                    ) : "") : (
                                    exam.status === 'DEPLOYED' ? (
                                    <Paper shadow="xs" p="md">
                                        <Stack gap="md">
                                            <Title order={2}>Before Starting the Exam</Title>
                                            <Text>
                                                Please read the instructions carefully before starting
                                                the exam. Understanding these guidelines is crucial to
                                                ensure a smooth examination process and to avoid any
                                                misunderstandings:
                                            </Text>
                                            <ul>
                                                <li>Ensure you have a stable internet connection throughout the exam.</li>
                                                <li>Have all necessary materials and equipment ready before beginning.</li>
                                                <li>Read each question carefully and manage your time wisely.</li>
                                            </ul>
                                            <Button loading={loadingStartExam} variant="filled" color="blue" onClick={startExam}>
                                                Start Exam
                                            </Button>
                                        </Stack>
                                    </Paper>
                                    ) : (
                                    <Alert variant="light" color="yellow" title="Exam Not Deployed" icon={<IconInfoCircle />}>
                                    This exam is not deployed yet. Please contact your team leader for more information.
                                    </Alert>)
                                )}
                            </Flex>

                        </Container>
                    </Grid.Col>
                </Grid>
            </>
        );
    }
}

export default ExamAttempt;
