//
//
//  Bar List
//
//

import { BarChart, BarList, Card, Metric } from "@tremor/react";
import { Flex, Title, LoadingOverlay } from "@mantine/core";


function VerticalBarList({
    data,
    title,
    loading
}: {
    data: Record<string, any>[];
    title?: string | null;
    loading: boolean;
}) {
    return (
        <Card>
            <LoadingOverlay visible={loading} />
            <Flex justify="space-between">
                <Flex direction="column" gap={10}>
                    <Metric className="text-right"></Metric>
                </Flex>
            </Flex>
            {title != null && (
                <Flex justify="center">
                    <Title order={3}>{title}</Title>
                </Flex>
            )}
            {data.length !== 0 ?
                <BarList
                    className="h-72 mt-4"
                    data={data.map(item => ({
                        value: item.value,
                        name: item.name,
                        ...item
                    }))}
                />
            :
                <BarChart
                    className="h-72 mt-4"
                    data={data}
                    index="xValue"
                    stack={true}
                    categories={[""]}
                    autoMinValue={true}
                />
            }
        </Card>
    );
}

export default VerticalBarList;