//
//
//  New Article Scratch Form
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, TextInput} from "@mantine/core";
import {useState} from "react";

function NewArticleScratchForm({onSubmit, onCancel}: {onSubmit: (values: any) => void, onCancel: () => void}) {
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            title: ""
        }
    })

    function onSubmitInner(values: any)  {
        setLoading(true)
        onSubmit(values)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
            <TextInput
                required
                withAsterisk
                label="Title"
                placeholder="Super duper article"
                {...form.getInputProps("title")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit" loading={loading}>Submit</Button>
            </Flex>
        </form>
    )
}

export default NewArticleScratchForm