import { Container, Flex, Grid, rem } from "@mantine/core";
import DashboardHeader from "../../components/DashboardHeader";
import HorizontalBarChart from "./Components/HorizontalBarChart";
import VerticalBarList from "./Components/VerticalBarChart";
import HorizontalLineChart from "./Components/HorizontalLineChart";
import { useTeam } from "../../contexts/TeamContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Api from "../../api";
import { DateRangePicker, DateRangePickerValue } from "@tremor/react";
import { formatRangeDate } from "../../utils";


export default function Overview() {
    const {team} = useTeam()
    const {getAccessTokenSilently} = useAuth0()
    const [conversationsByDate, setConversationsByDate] = useState([])
    const [conversationsDist, setConversationsDist] = useState([])
    const [membersRanking, setMembersRanking] = useState([])
    const [marksDist, setMarksDist] = useState([])
    const [conversationsByDateLoading, setConversationsByDateLoading] = useState<boolean>(false)
    const [conversationsDistLoading, setConversationsDistLoading] = useState<boolean>(false)
    const [membersRankingLoading, setMembersRankingLoading] = useState<boolean>(false)
    const [marksDistLoading, setMarksDistLoading] = useState<boolean>(false)

    const today = new Date()
    const previousMonth = new Date()
    previousMonth.setMonth(previousMonth.getMonth() - 1)
    const [dateRange, setDateRange] = useState<DateRangePickerValue>({from: previousMonth, to: today})
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    function setDate(dateRange: any) {
        setStartDate(formatRangeDate(dateRange["from"]))

        const auxDate = new Date(dateRange["to"])
        auxDate.setDate(auxDate.getDate() + 1);
        setEndDate(formatRangeDate(auxDate))
    }

    async function getConversationsByDate() {
        if (team == null) return
        if (startDate == '' || endDate == '') return

        setConversationsByDateLoading(true)

        Api.getConversationsByDate(await getAccessToken(), team.uuid, startDate, endDate)
            .then((response) => {
                setConversationsByDate(response)
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setConversationsByDateLoading(false)
            }) 
    }

    async function getConversationsDist() {
        if (team == null) return
        if (startDate == '' || endDate == '') return

        setConversationsDistLoading(true)

        Api.getConversationsDistribution(await getAccessToken(), team.uuid, startDate, endDate)
            .then((response) => {
                setConversationsDist(response)
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setConversationsDistLoading(false)
            }) 
    }

    async function getMembersRanking() {
        if (team == null) return
        if (startDate == '' || endDate == '') return

        setMembersRankingLoading(true)

        Api.getMembersRanking(await getAccessToken(), team.uuid, startDate, endDate)
            .then((response) => {
                setMembersRanking(response)
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setMembersRankingLoading(false)
            }) 
    }

    async function getMarksDist() {
        if (team == null) return
        if (startDate == '' || endDate == '') return
        
        setMarksDistLoading(true)

        Api.getMarksDistribution(await getAccessToken(), team.uuid, startDate, endDate)
            .then((response) => {
                setMarksDist(response)
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setMarksDistLoading(false)
            }) 
    }

    useEffect(() => {
        setDate(dateRange)
    }, [dateRange])

    useEffect(() => {
        setDate(dateRange)

        getConversationsByDate()
        getConversationsDist()
        getMembersRanking()
        getMarksDist()
    }, [team, startDate, endDate])

    return (
        <>
            <DashboardHeader
                title="Overview"
            />

            <Container fluid mt={20}>
                <Flex
                    direction="row-reverse"
                    mt={rem(30)}
                    mb={rem(30)}
                >
                <DateRangePicker
                    value={dateRange}
                    onValueChange={setDateRange}
                    weekStartsOn={1}
                    enableClear={false}
                />
                </Flex>

                <Grid>
                    <Grid.Col span={6}>
                        <HorizontalLineChart
                            data={conversationsByDate}
                            categories={['Conversations']}
                            title={"Conversations per day"}
                            loading={conversationsByDateLoading}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <HorizontalBarChart
                            data={conversationsDist}
                            categories={['Count']}
                            title={"Conversations duration (minutes)"}
                            loading={conversationsDistLoading}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <HorizontalBarChart
                            data={marksDist}
                            categories={['Count']}
                            title={"Exam marks distribution"}
                            loading={marksDistLoading}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <VerticalBarList
                            data={membersRanking}
                            title={"Team members ranking"}
                            loading={membersRankingLoading}
                        />
                    </Grid.Col>
                </Grid>
            </Container>
        </>
    )
}
