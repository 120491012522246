//
//
//  Admin
//
//

import { Outlet } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout.tsx";


function Admin() {
    return (
        <AdminLayout>
            <Outlet/>
        </AdminLayout>
    )
}

export default Admin
