//
//
//  Home
//
//

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppLayout from '../../layouts/AppLayout';
import { useTeam } from '../../contexts/TeamContext';

function Home() {
  const { role } = useTeam();
  const location = useLocation();

  const editor_paths = ['/', '/exams', '/conversations', '/lessons', '/overview', '/personalities'];
  const viewer_paths = ['/', '/exams', '/conversations', '/lessons'];

  const rootPath = "/"+location.pathname.split('/')[1];
  const isAuthorized = role === 'EDITOR' ? editor_paths.includes(rootPath) : viewer_paths.includes(rootPath);

  function RedirectToUnauthorized() {
    if (location.pathname === '/overview') {
      return <Navigate to="/conversations" />
    }
    return <Navigate to="not-found"/>
  }

  return (
    <AppLayout>
      {isAuthorized ? <Outlet /> : <RedirectToUnauthorized />}
    </AppLayout>
  );
}

export default Home;