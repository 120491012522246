//
//
//  Admin hooks
//
//


import {useAuth0} from "@auth0/auth0-react";

export default function useAdmin() {
    const {user} = useAuth0()
    if (user == null) {
        return false
    }

    return user["https://maia-cognitive.eu/is_admin"] || false
}