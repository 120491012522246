import { Button, Flex, Text, Title } from "@mantine/core";
import DashboardHeader from "../../components/DashboardHeader";
import { modals } from "@mantine/modals";
import Create from "./Create";
import { useTeam } from "../../contexts/TeamContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Api from "../../api";
import { ExamInterface, LessonInterface } from "../../interfaces";
import { IconMoodSad, IconVocabulary } from "@tabler/icons-react";
import classes from "./Index.module.css";
import { DataTable } from "mantine-datatable";
import { useNavigate } from "react-router-dom";
import ExamDashboard from "./Components/ExamDashboard";
import { notifications } from "@mantine/notifications";

const PAGE_SIZE = 25;

function Exams() {
  const navigate = useNavigate();
  const { team, role } = useTeam();
  const { getAccessTokenSilently } = useAuth0();
  const [lessons, setLessons] = useState<LessonInterface[]>([]);
  const [exams, setExams] = useState<ExamInterface[]>([]);
  const pageLength = 20;
  const [examsLoading, setExamsLoading] = useState(false);
  const [page] = useState(1);
  const [selectedExam, setSelectedExam] = useState<ExamInterface>();

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });
    return accessToken;
  }

  async function fetchLessons() {
    try {
      Api.getTeamLessons(await getAccessToken(), team!.uuid, 1, PAGE_SIZE)
        .then((pageLessons) => {
          let lessonToSelect = null;
          const cacheSelectedLessonUudText = sessionStorage.getItem(
            "dashboard-trainer-selected-lesson"
          );
          if (cacheSelectedLessonUudText !== null) {
            lessonToSelect = pageLessons.items.find(
              (lesson: LessonInterface) => {
                const isMatch = lesson.uuid === cacheSelectedLessonUudText;
                return isMatch;
              }
            );
          }
          if (
            lessonToSelect == null &&
            pageLessons.items.length > 0
          ) {
            lessonToSelect = pageLessons.items[0];
          }

          setLessons(pageLessons.items);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchExams() {
    setExamsLoading(true);
    let examsPromise;
  
    if (role === 'EDITOR') {
      examsPromise = Api.getTeamExams(await getAccessToken(), team!.uuid, page, pageLength);
    } else {
      examsPromise = Api.getTeamExamsDeployed(await getAccessToken(), team!.uuid, page, pageLength);
    }
  
    examsPromise
      .then((pageExams) => {
        let examToSelect = null;
        const cacheSelectedExamUudText = sessionStorage.getItem(
          "dashboard-trainer-selected-lesson"
        );
        if (cacheSelectedExamUudText !== null) {
          examToSelect = pageExams.items.find((exam: ExamInterface) => {
            const isMatch = exam.uuid === cacheSelectedExamUudText;
            return isMatch;
          });
        }
        if (
          examToSelect == null &&
          pageExams.items.length > 0
        ) {
          examToSelect = pageExams.items[0];
        }
  
        // filter exams if status is not deployed
        const filteredExams = pageExams.items.filter((exam: ExamInterface) => {
          return exam.status == "DEPLOYED";
        });
        if (role === 'EDITOR') {
          setExams(pageExams.items);
        } else {
          setExams(filteredExams);
        }
        setSelectedExam(examToSelect);
        setExamsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  async function createExam(values: any) {
    Api.createExam(
      await getAccessToken(),
      values.name,
      values.selectedLesson,
      values.time_limit,
      values.pool_size
    ).then((response) => {
      navigate("/exams/edit/" + response.uuid);
    }).catch((error) => {
      console.error(error);
      notifications.show(
        {
          title: "Failed to create exam",
          message: "An error occurred while creating the exam",
          color: "red",
        })
    }
    );

    modals.closeAll();
  }

  const createExamModal = async () => {
    modals.open({
      title: "Create Exam",
      children: (
        <Create
          lessons={lessons}
          onSubmit={(values) => {
            createExam(values);
          }}
          onCancel={() => {
            modals.closeAll();
          }}
        />
      ),
    });
  };
  useEffect(() => {
    fetchLessons();
    fetchExams();
  }, [ team]);

  function onSelectExam(newExam: any) {
    setSelectedExam(newExam);
    sessionStorage.setItem("dashboard-trainer-selected-exam", newExam.uuid);
  }

  const header = (
      <DashboardHeader
        rightSection={role === 'EDITOR' ? 
        <Button
        disabled={lessons.length === 0}
        onClick={createExamModal}
      >
        Generate New Exam
      </Button>: undefined}
        title="Exams"
      />
    );
  
  return (
    <>
      {header}
      <Flex>
        <nav className={classes.nav}>
          <DataTable
            noHeader
            records={exams}
            withRowBorders={false}
            noRecordsText="There are no exams yet"
            columns={[
              {
                accessor: "title",
                ellipsis: true,
                width: 300,
                render: (exam) => {
                  return (
                    <Button
                      size="xs"
                      onClick={() => onSelectExam(exam)}
                      variant={
                        exam.uuid == selectedExam?.uuid ? "filled" : "subtle"
                      }
                      key={exam.uuid}
                      style={{ width: "100%" }}
                      classNames={{ inner: classes.navButton }}
                    >
                      <Flex
                        gap={10}
                        align="center"
                        className={classes.examNameContainer}
                      >
                        <IconVocabulary size={16} />
                        <Text size="sm" className={classes.examName}>
                          {exam.name}
                        </Text>
                      </Flex>
                    </Button>
                  );
                },
              },
            ]}
            fetching={examsLoading}
            className={classes.navTable}
            minHeight={lessons.length > 0 ? 0 : 300}
            emptyState={
              <Flex justify="center" align="center" direction="column" gap={20}>
                <IconMoodSad size={60} />
                <Title order={4}>No Exams found</Title>
                <Button>Create Exam</Button>
              </Flex>
            }
          />
        </nav>
        <Flex ml={20} mr={20} style={{width: "100%"}}>
          {selectedExam && (
            <ExamDashboard
              exam={selectedExam}
              onDelete={(deletedExam: any) => {
                setExams(exams.filter((exam) => exam.uuid != deletedExam.uuid));
                setSelectedExam(undefined);
              }}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}

export default Exams;
