//
//
//  Socket Context
//
//

import {io, Socket} from "socket.io-client";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";


function initSocket() {
    return io({
        autoConnect: false
    })
}

interface SocketContextInterface {
    socket: Socket
}

const SocketContext = createContext<SocketContextInterface | undefined>(undefined)

export const SocketProvider = function({children}: {children: ReactNode}) {
    const { getAccessTokenSilently } = useAuth0()
    const [socket, setSocket] = useState<Socket>(initSocket)
    const [accessToken, setAccessToken] = useState<string | null>(null)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       if ((typeof accessToken) === "string") {
            setAccessToken(accessToken)
       }
    }

    useEffect(() => {
        getAccessToken()
    }, [])

    useEffect(() => {
        if (accessToken == null) {
            setSocket(initSocket())
        }

        const newSocket = io(import.meta.env.VITE_URL, {
            path: "/ws/socket.io",
            transports: ["websocket", "polling", "flashsocket"],
            auth: {
                token: accessToken
            }
        })

        setSocket(newSocket)

        return () => {
            newSocket.disconnect()
        }
    }, [accessToken])

    return (
        <SocketContext.Provider value={{socket: socket}}>
            {children}
        </SocketContext.Provider>
    )
}

export function useSocket() {
    const socketContext = useContext(SocketContext)
    if (!socketContext) {
        throw new Error("No SocketContext.Provider found when calling useSocket.")
    }
    return socketContext
}
