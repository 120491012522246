//
//
//  Team Context
//
//

import {Team, TeamMember} from "../interfaces.ts"
import {createContext, ReactNode, useContext, useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react";
import {Anchor, Button, Flex, LoadingOverlay, Title, Text} from "@mantine/core";
import Api from "../api.ts";
import SwitchTeamsModal from "../components/SwitchTeamsModal.tsx";
import useAdmin from "../hooks/admin.ts";
import { useNavigate } from "react-router-dom";

interface TeamContextInterface {
    team: Team | null
    setTeam: (team: Team) => void
    role: string
    permissionLevel: string
    setRole: (role: string) => void
    teamMemberUuid: string
    setTeamMemberUuid: (uuid: string) => void
}

const TeamContext = createContext<TeamContextInterface | undefined>(undefined)

export const TeamProvider = function ({children}: { children: ReactNode }) {
    const {logout} = useAuth0()
    const navigate = useNavigate()
    const isAdmin = useAdmin()
    const [unauthorized, setUnauthorized] = useState(false)
    const [noTeams, setNoTeams] = useState(false)
    const [userRole, setUserRole] = useState<string>()
    const [permissionLevel, setPermissionLevel] = useState<string>()
    const {user, getAccessTokenSilently} = useAuth0()
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [currentTeam, setCurrentTeam] = useState<Team>()
    const [memberUuid, setMemberUuid] = useState<string>("")

    async function getAccessToken() {
        await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       }).then(accessToken => setAccessToken(accessToken))
       .catch(() => {
            setUnauthorized(true)}
        )

    }

    useEffect(() => {
        try {
            getAccessToken()
        } catch (error) {
            setUnauthorized(true)
        }
    }, [])

    async function getTeams(token: string) {
        Api.getTeamsBelong(token, 1, 100)
            .then((teams: any) => {
                if (teams["items"].length === 0) {
                    setNoTeams(true)
                } else {
                    // Select the first team
                    setCurrentTeam(teams["items"][0])
                    localStorage.setItem("teamUuid", teams["items"][0].uuid)
                }
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setLoading(false)
        })
    }

    async function getTeam(token: string, teamUuid: string) {
        Api.getTeam(token, teamUuid)
            .then((team) => {
                if (team != null) {
                    setCurrentTeam(team)
                    return
                }
                
            }).catch(error => {
                localStorage.removeItem("teamUuid")
                console.error(error)
            }).finally(() => {
                setLoading(false)
            })
        }

    useEffect(() => {
        if (accessToken == null) {
            return
        }
        const teamUuid = localStorage.getItem("teamUuid")
        if (teamUuid === null) {
            sessionStorage.removeItem("dashboard-trainer-selected-lesson")
            getTeams(accessToken)
        } else {
            getTeam(accessToken, teamUuid)
        }   
    }, [accessToken])

    useEffect(() => {
        if (currentTeam != null) {
            if (!user || user.sub === undefined) {
                return;
            }

            Api.getTeamMember(accessToken!, currentTeam.uuid, user.sub)
            .then((member: TeamMember) => {
                setPermissionLevel(member.role)
                setMemberUuid(member.uuid)

                const auxRole = localStorage.getItem('userRole')

                if (auxRole === "EDITOR" && member.role === "VIEWER") {
                    setUserRole("VIEWER")
                } else if (auxRole !== null) {
                    setUserRole(auxRole)
                } else {
                    setUserRole(member.role)
                }
            })
            localStorage.setItem("teamUuid", currentTeam.uuid)

        }
    }, [currentTeam])

    useEffect(() => {
        if (userRole != null) {
            localStorage.setItem('userRole', userRole)
        }
    }, [userRole])

    useEffect(() => {
        if (memberUuid != null) {
            localStorage.setItem('teamMemberUuid', memberUuid)
        }
    }, [memberUuid])

    if (unauthorized) {
        return  <TeamContext.Provider value={{
                    team: null,
                    setTeam: setCurrentTeam, 
                    role: "",
                    permissionLevel: "",
                    setRole: setUserRole,
                    teamMemberUuid: "",
                    setTeamMemberUuid: setMemberUuid
                }}>
                    {children}
                </TeamContext.Provider>
    }

    if (loading) {
        return <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
    }

    if (noTeams) {
        if (isAdmin) {
            return (
                <Flex style={{minHeight: "100vh"}} align="center" justify="center" direction="column" gap={16}>
                    <Title order={2}>No teams available</Title>
                    <Button onClick={() => navigate("/admin")}>Admin</Button>
                </Flex>
            )
        }

        return (
            <Flex style={{minHeight: "100vh"}} align="center" justify="center" direction="column" gap={16}>
                <Title order={2}>No teams available</Title>
                <Text c="dimmed">
                    Please contact <Anchor href="mailto:miguel.castanedo@majorel.com?subject=%5BMAIA%20Trainer%20Base%5D%20No%20team%20available">Miguel Castanedo</Anchor> if you think this is an error.
                </Text>
                <Button onClick={() => logout()}>Logout</Button>
            </Flex>
        )
    }

    if (currentTeam == null) {
        return (
            <SwitchTeamsModal
                opened={true}
                onSelect={(team) => setCurrentTeam(team)}
                withCloseButton={false}
                onClose={() => void 0}
            />
        )
    }

    if (userRole == null || permissionLevel == null) {
        return <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
    }

    return (
        <TeamContext.Provider value={{
            team: currentTeam,
            setTeam: setCurrentTeam, 
            role: userRole,
            permissionLevel: permissionLevel,
            setRole: setUserRole,
            teamMemberUuid: memberUuid,
            setTeamMemberUuid: setMemberUuid
        }}>
            {children}
        </TeamContext.Provider>
    )
}

export function useTeam() {
    const teamContext = useContext(TeamContext)
    if (!teamContext) {
        throw new Error("No TeamContext.Provider found when calling useTeam.")
    }
    return teamContext
}
