import classes from "./GearRotator.module.css";

export default function GearRotator() {
  return (
    <div id="wrapper">
    <div className={classes.gears} id="two-gears">
        <div className={classes.gearsContainer}>
            <div className={classes.gearRotate}></div>
            <div className={classes.gearRotateLeft}></div>
        </div>
    </div>
    </div>
  )
}
