//
//
//  Team List
//
//

import {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Container,
    Flex,
    rem,
    Title
} from "@mantine/core";
import {IconCirclePlus} from "@tabler/icons-react"
import { Socket } from "socket.io-client";

import Api from "../../../api.ts"
import TeamsDataTable from "./DataTable.tsx";
import {Team} from "../../../interfaces.ts";
import {useSocket} from "../../../contexts/SocketContext.tsx";
import {onResourceAdd, onResourceDelete, onResourceUpdate, sortByUpdatedAt} from "../../../utils.ts";
import PaginationControl from "../../../components/PaginationControl.tsx";
import TeamCreateModal from "./Create.tsx";


function AdminTeamsList() {
    const pageLength = 3

    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate()
    const { socket }: { socket: Socket } = useSocket()
    const teamsRef = useRef<Team[]>([])
    const [teams, setTeams] = useState<Team[]>([])
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [totalTeams, setTotalTeams] = useState(0)
    const [page, setPage] = useState(1)
    const newElementsRef = useRef<Team[]>([])
    const [newElements, setNewElements] = useState<Team[]>([])
    const [createOpen, setCreateOpen] = useState(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadTeamsPage() {
        setLoading(true)
        Api.getTeams(await getAccessToken(), page, pageLength)
            .then(pageTeams => {
                teamsRef.current = pageTeams["items"]
                setTeams(sortByUpdatedAt(teamsRef.current))
                setTotalTeams(pageTeams["total"])
                setLoading(false)
            }).catch(console.error)
    }


    async function onDeleteTeam(team: Team) {
        setUpdating(true)
        Api.deleteTeam(await getAccessToken(), team.uuid)
            .then(() => {
                setUpdating(false)
                localStorage.removeItem("teamUuid")
            })
    }


    useEffect(() => {
        newElementsRef.current = []
        setNewElements(newElementsRef.current)
        loadTeamsPage()
    }, [page])


    useEffect(() => {
        function onTeamAdd(added: Team) {
            teamsRef.current = onResourceAdd(added, teamsRef.current)
            newElementsRef.current = onResourceAdd(added, newElementsRef.current)
            setNewElements(newElementsRef.current)
        }

        function onTeamUpdate(updated: Team) {
            teamsRef.current = onResourceUpdate(updated, teamsRef.current)
            newElementsRef.current = []
            setNewElements(newElementsRef.current)
            setPage(1)
        }

        function onTeamDelete(deleted: Team) {
            teamsRef.current = onResourceDelete(deleted, teamsRef.current)
            
            newElementsRef.current.forEach(element => {
                if (element.uuid === deleted.uuid) {
                    newElementsRef.current = onResourceDelete(deleted, newElementsRef.current)
                    setNewElements(newElementsRef.current)
                }
            });

            loadTeamsPage()
        }

        socket.on("team:add", onTeamAdd)
        socket.on("team:update", onTeamUpdate)
        socket.on("team:delete", onTeamDelete)

        return () => {
            socket.off("team:add", onTeamAdd)
            socket.off("team:update", onTeamUpdate)
            socket.off("team:delete", onTeamDelete)
        }
    }, [socket])


    return (
        <>
            <main>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                        direction="row"
                        mt={rem(50)}
                        mb={rem(30)}
                    >
                        <Title size="h1">Teams</Title>
                        <Button
                            onClick={() => setCreateOpen(true)}
                            leftSection={<IconCirclePlus size={16}/>}
                        >
                            Create Team
                        </Button>
                    </Flex>
                    <TeamsDataTable
                        teams={teams}
                        newTeams={newElements}
                        loading={loading}
                        updating={updating}
                        onCreate={() => setCreateOpen(true)}
                        onSelection={team => navigate(`/admin/teams/${team.uuid}`)}
                        onDelete={onDeleteTeam}
                    />
                    <PaginationControl 
                        totalElements={totalTeams}
                        page={page}
                        pageLength={pageLength}
                        onChange={setPage}
                    />

                </Container>
                <TeamCreateModal
                    opened={createOpen}
                    setOpened={setCreateOpen}
                />
            </main>
        </>
    )
}

export default AdminTeamsList
