//
//
//  New Article Modal
//
//


import {modals} from "@mantine/modals";
import AudioCreationForm from "./AudioCreationForm.tsx";
import AudioFileForm from "./AudioFileForm.tsx";
import NewAudioStart from "./NewAudioStart.tsx";
import { AzureVoice } from "../../../interfaces.ts";


function AudioCreationModal({voices, onSubmit}: {voices: AzureVoice[], onSubmit: (values: any) => void}) {
    return (
        <NewAudioStart
            onSubmit={(startType) => {
                if (startType === "tts") {
                    modals.open({
                        title: "Generate audio with TTS",
                        children: (
                            <AudioCreationForm
                                voices={voices}
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else if (startType === "files") {
                    modals.open({
                        title: "Upload existing audio file",
                        children: (
                            <AudioFileForm
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else {
                    throw new Error("Invalid start type")
                }
            }}
        />
    )
}

export default AudioCreationModal