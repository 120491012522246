//
//
//  Edit Lesson Modal
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, Textarea, TextInput} from "@mantine/core";

function EditLessonModal({lesson, onSubmit, onCancel}: {lesson: any, onSubmit: (values: any) => void, onCancel: () => void}) {
    const form = useForm({
        initialValues: {
            name: lesson.name,
            description: lesson.description,
            enabled: !lesson.disabled
        }
    })

    function onSubmitInner(values: any) {
        const submitValues = Object.assign({}, values, {disabled: !values.enabled})
        onSubmit(submitValues)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
            <TextInput
                required
                withAsterisk
                label="Title"
                placeholder="Super duper lesson"
                {...form.getInputProps("name")}
            />
            <Textarea
                mt={12}
                label="Description"
                placeholder="Super duper description"
                {...form.getInputProps("description")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </Flex>
        </form>
    )
}

export default EditLessonModal