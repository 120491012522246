//
//
//  New Article Modal
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, Select} from "@mantine/core";
import {useState} from "react";
import { AzureVoice } from "../../../interfaces";

function AudioCreationForm({voices, onSubmit, onCancel}: {voices: AzureVoice[], onSubmit: (values: any) => void, onCancel: () => void}) {
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            azure_voice_uuid: "",
            startType: "tts"
        }
    })

    function onSubmitInner(values: any)  {
        setLoading(true)
        onSubmit(values)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
             <Select label="Voice" 
                        {...form.getInputProps("azure_voice_uuid")}
                        placeholder="Select voice"
                        clearable
                        searchable                        
                        data={voices.map((voice) => ({ value: voice.uuid, label: voice.name }))}                 
                    />       
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit" loading={loading}>Submit</Button>
            </Flex>
        </form>
    )
}

export default AudioCreationForm
