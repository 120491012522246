//
//
//  Require Logged In
//
//

import {Outlet} from "react-router-dom"
import { TeamProvider } from "../contexts/TeamContext.tsx";



 function RequireTeamProvider() {
     return (
        <>
            <TeamProvider>
                <Outlet/>
            </TeamProvider>
        </>
    )
 }

export default RequireTeamProvider
