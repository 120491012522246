import React, { useState } from 'react';
import { Text, Flex, ActionIcon, TextInput } from '@mantine/core';
import { IconPencil, IconX } from '@tabler/icons-react';

interface EditableTextProps {
  initialText: string;
  onSave: (newText: string) => void;
  disabled?: boolean;
}

const EditableText = ({ initialText, onSave, disabled }: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [hasChanged, setHasChanged] = useState(false)

  const handleIconClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputBlur = () => {
      setIsEditing(false);
      if (hasChanged) {
        onSave(text);
      }
    }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == initialText) {
      setHasChanged(false)
    } else {
        setHasChanged(true)
    }
    setText(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      if (hasChanged) {
        onSave(text);
        }
    }
  };

  if (disabled) {
    return (
    <Flex align="center" w={'full'} justify="space-between" gap={5}>
      <Text>{text}</Text>
    </Flex>
    )
  }

  return (
    <Flex align="center" w={'full'} justify="space-between" gap={5}>
      {isEditing ? (
        <TextInput
          autoFocus
          value={text}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      ) : (
        <Text onClick={() => setIsEditing(true)}>{text}</Text>
      )}
      {!isEditing ? (
        <ActionIcon variant="subtle" onClick={handleIconClick}>
          <IconPencil size={16} />
        </ActionIcon>
      ): (
        <ActionIcon variant="subtle" onClick={handleIconClick}>
          <IconX color='red' size={16} />
        </ActionIcon>

      )
    }
    </Flex>
  );
};

export default EditableText;
