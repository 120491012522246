//
//
//  App Layout
//
//

import { Link, useLocation, useNavigate } from "react-router-dom";

import classes from "./AppLayout.module.css";
import { Group, Image, Badge, Flex } from "@mantine/core";
import {
  IconChartLine,
  IconFriends,
  IconBooks,
  IconNotes,
  IconMessages
} from "@tabler/icons-react";
import logo from "../assets/logo.svg";
//import { generateColorFromString, getMetaContent } from "../utils.ts";
import { ReactNode } from "react";
import ProfileMenu from "../components/ProfileMenu.tsx";
import { useTeam } from "../contexts/TeamContext.tsx";

function AppLayout({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();

  //const appName = getMetaContent("APP_NAME");
  const {team, role, setRole} = useTeam()
  
  const navigate = useNavigate()

  function userHasRole(comparingRole: string) {
    if (role === comparingRole) {
      return true;
    }
  }

  function switchView() {
    if (role === 'EDITOR') {
      setRole('VIEWER')
      navigate('/conversations')
    } else {
      setRole('EDITOR')
      navigate('/overview')
    }
  }

  const editorLinks = [
    {
      link: "/",
      label: "Overview",
      icon: IconChartLine,
      active: pathname === "/overview",
    },
    {
      link: "/personalities",
      label: "Profiles",
      icon: IconFriends,
      active: pathname === "/personalities",
    },
    {
      link: "/conversations",
      label: "Conversations",
      icon: IconMessages,
      active: pathname === "/conversations",
    },
    {
      link: "/lessons",
      label: "Lessons",
      icon: IconBooks,
      active: pathname === "/lessons",
    },
    {
      link: "/exams",
      label: "Exams",
      icon: IconNotes,
      active: pathname.startsWith("/exams"),
    },
  ];

  const viewerLinks = [
    {
      link: "/conversations",
      label: "Conversations",
      icon: IconMessages,
      active: pathname === "/conversations",
    },
    {
      link: "/lessons",
      label: "Lessons",
      icon: IconBooks,
      active: pathname === "/lessons",
    },
    {
      link: "/exams",
      label: "Exams",
      icon: IconNotes,
      active: pathname.startsWith("/exams"),
    },
  ];

  return (
    <>
      <nav className={classes.navbar}>
        <div className={classes.navbarMain}>
          <Group className={classes.header} justify="center">
            <Link to="/">
              <Image src={logo} h={60} w="auto" fit="contain" />
            </Link>
              <Flex justify="center" align="center" mb={12}> 
                <Badge variant="outline" color="blue" size="md" radius="sm">{team?.name}</Badge>
             </Flex>
          </Group>
          {userHasRole("EDITOR")
            ? editorLinks.map((link) => (
                <Link
                  className={classes.link}
                  data-active={link.active || undefined}
                  to={link.link}
                  key={link.link}
                >
                  <link.icon className={classes.linkIcon} stroke={1.5} />
                  <span>{link.label}</span>
                </Link>
              ))
            : viewerLinks.map((link) => (
                <Link
                  className={classes.link}
                  data-active={link.active || undefined}
                  to={link.link}
                  key={link.link}
                >
                  <link.icon className={classes.linkIcon} stroke={1.5} />
                  <span>{link.label}</span>
                </Link>
              ))}
        </div>
        <div className={classes.footer}>
          <Flex direction="column">
            <ProfileMenu 
              includeDashboard={false} 
              includeAdmin={true}
              onChangeView={switchView}
              currentUserRole={role}
             />
          </Flex>
        </div>
      </nav>
      <main className={classes.main}>
        {children}
      </main>
    </>
  );
}

export default AppLayout;
