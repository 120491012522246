import {
  ActionIcon,
  Button,
  Flex,
  Menu,
  Text,
  Title,
} from "@mantine/core";
import {
  IconDots,
  IconEdit,
  IconEye,
  IconTrash,
} from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils";
import { ExamAttemptInterface, ExamInterface } from "../../../interfaces";
import { modals } from "@mantine/modals";
import Api from "../../../api";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTeam } from "../../../contexts/TeamContext";
import { intToHoursMinutesSeconds } from "../../../utils";


const PAGE_SIZE = 25;

function ExamDashboard({
  exam,
  onDelete,
}: {
  exam: ExamInterface;
  onDelete: (exam: ExamInterface) => void;
}) {

  const navigate = useNavigate()
  const { role } = useTeam()
  const { getAccessTokenSilently } = useAuth0()
  const [examAttempts, setExamAttempts] = useState<ExamAttemptInterface[]>([]);
  const [examAttemptsLoading, setExamAttemptsLoading] = useState(false);
  const [loadAttemptsPage, setLoadAttemptsPage] = useState(1);
  const [currentAttemptsPage, setCurrentAttemptsPage] = useState(false);

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });
    return accessToken;
  }

  async function fetchExamAttempts() {
    setExamAttemptsLoading(true);
    Api.getExamAttempts(await getAccessToken(), exam.uuid, loadAttemptsPage, PAGE_SIZE)
      .then((pageAttempts) => {
        setExamAttempts(pageAttempts.items);

        if (pageAttempts.items.length === 0) {
          setCurrentAttemptsPage(false);
        } else {
          setCurrentAttemptsPage(pageAttempts.page);
        }

        setExamAttemptsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setExamAttemptsLoading(false);
      });
  }

  const deleteExamModal = () => {
    modals.openConfirmModal({
      title: "Delete Exam",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete the exam? This action is destructive
          and you will not be able to recover the exam once it's deleted.
        </Text>
      ),
      labels: { confirm: "Delete exam", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {
        modals.closeAll();
      },
      onConfirm: async () => {
        try {
          const accessToken = await getAccessToken();
          await Api.deleteExam(accessToken, exam.uuid);
          onDelete(exam);
        } catch (error) {
          console.error(error);
        } finally {
          modals.closeAll();
        }
      },
    });
  };

  useEffect(() => {
    fetchExamAttempts();
  }, [exam, loadAttemptsPage]);


  return (
    <Flex direction="column" style={{ width: "100%" }} gap={20}>
      <Flex direction="row" justify="space-between" style={{ width: "100%" }}>
        <Flex direction="column" gap={10}>
          <Title order={5} mt={20}>
            {exam.name}
          </Title>
        </Flex>
        <Flex align="center" justify={'center'} gap={20}>
          {role === 'EDITOR' ? (
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon variant="outline">
                  <IconDots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => navigate('/exams/edit/' + exam.uuid)}
                  leftSection={<IconEdit size={16} />}
                >
                  Edit
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  color="red"
                  onClick={deleteExamModal}
                  leftSection={<IconTrash size={16} />}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) :
            (
              <Button onClick={() => navigate("/exams/take/" + exam.uuid)}>Take an exam</Button>
            )}
        </Flex>
      </Flex>
      <Flex direction="column">
        <Title order={3} mb={12}>
          Exam attempts
        </Title>
        <DataTable
          fetching={examAttemptsLoading}
          noRecordsText="There are no exam attempts yet"
          columns={[
            {
              accessor: "status",
              textAlign: "left",
              render: ({ status }) => {
                return (
                  <Text size="sm" fw={500}>
                    {status}
                  </Text>
                );
              },
            },
            {
              accessor: "duration",
              title: "Duration",
              render: ({ duration }) => {
                return (
                  <Text size="sm">{duration == null ? "-" : intToHoursMinutesSeconds(duration)}</Text>
                );
              },
            },
            {
              accessor: "mark",
              title: "Final mark",
              render: ({ mark }) => {
                return (
                  <Text size="sm" fw={500}>
                    {mark} / 10
                  </Text>
                );
              },
            },
            {
              accessor: "updated_at",
              title: "Updated at",
              render: ({ updated_at }) => {
                return (
                  <Text size="sm">
                    {updated_at == null
                      ? "-"
                      : formatDate(new Date(updated_at))}
                  </Text>
                );
              },
            },
            {
              accessor: "created_at",
              title: "Created at",
              render: ({ created_at }) => {
                return (
                  <Text size="sm">
                    {created_at == null
                      ? "-"
                      : formatDate(new Date(created_at))}
                  </Text>
                );
              },
            },
            {
              accessor: "actions",
              title: ((role === "VIEWER") ? 'Actions' : 'Student'),
              textAlign: ((role === "VIEWER") ? 'right' : 'left'),
              render: (examAttempt) => {
                return (
                  <>
                    {role === 'VIEWER' ? (
                        <Flex align="center" justify={"flex-end"} gap={6}>
                          <div>
                            <ActionIcon variant="subtle"  onClick={() => navigate("/exams/preview/" + examAttempt.uuid)}>
                              <IconEye size={14} />
                            </ActionIcon>
                          </div>
                        </Flex>
                      )
                    :
                      <Text size="sm">{examAttempt.created_by.name}</Text>
                    }
                  </>
                )
              }
            }
          ]}
          records={examAttempts}
          minHeight={examAttempts.length > 0 ? 0 : 300}
          totalRecords={examAttempts.length}
          recordsPerPage={PAGE_SIZE}
          // @ts-expect-error
          page={currentAttemptsPage}
          onPageChange={(p) => setLoadAttemptsPage(p)}
        />
      </Flex>
    </Flex>
  );
}

export default ExamDashboard;
