import {
  Checkbox,
  Flex,
  Grid,
  Paper,
  Skeleton,
  Text,
} from "@mantine/core";
import Api from "../../../api";
import {
  ExamAnswerTextInterface,
  ExamAttemptQuestionInterface,
} from "../../../interfaces";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";


function QuestionAttempt({
  question
}: {
  question: ExamAttemptQuestionInterface;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [answers, setAnswers] = useState<ExamAnswerTextInterface[]>();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<ExamAttemptQuestionInterface>(question);

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });

    return accessToken;
  }

  async function fetchQuestion() {
    Api.getExamAttemptQuestion(await getAccessToken(), currentQuestion.uuid).then(
      (question) => {
        setCurrentQuestion(question);
      }
    ).catch((error) => {
        console.error(error);
      }
    )
  }

  async function fetchAnswers() {
    if (!currentQuestion) {
      return;
    }
    setIsSaving(false)
    Api.getExamAttemptQuestionAnswers(await getAccessToken(), currentQuestion.question_uuid, 1, 25).then(
      (pageAnswers) => {
        setAnswers(pageAnswers.items);
      }
    );
  }

  async function handleSelectAnswer(answer: ExamAnswerTextInterface) {
    if (!answers) {
      return;
    }
    setIsSaving(true);

    await Api.updateExamAttemptQuestion(await getAccessToken(), currentQuestion.uuid, { answer_uuid: answer.uuid }).then(
      (updatedQuestion) => {
        setCurrentQuestion(updatedQuestion);
      }
    ).catch((error) => {
        console.error(error);
      }
    )
    setIsSaving(false);
  }

  useEffect(() => {
    setIsLoading(true)
    setAnswers(undefined);

    if (!question) {
      setIsLoading(false)
      return;
    }
    
    fetchQuestion();
    fetchAnswers();
    setIsLoading(false)
  }, [question]);

  return (
    <Paper p={"xl"} radius={"sm"} shadow="sm">
      <Flex justify={"space-between"}>
        <Text>{currentQuestion.text}</Text>
      </Flex>
      <Flex direction={"column"} m={10} gap={10}>
        {isLoading || !answers ? (
          <>
            <Skeleton height="20px" width="100%" />
            <Skeleton height="20px" width="100%" />
            <Skeleton height="20px" width="100%" />
          </>
        ) : (
          answers.map((answer) => (
            <Grid key={answer.uuid} columns={12} w={'full'}>
              <Grid.Col span={11}>
                <Text>{answer.text}</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex justify={"center"}>
                  <Checkbox
                    disabled={isSaving || isLoading}
                    onChange={() => handleSelectAnswer(answer)}
                    checked={currentQuestion?.answer_uuid === answer.uuid} />
                </Flex>
              </Grid.Col>
            </Grid>
          ))
        )}
      </Flex>
    </Paper>
  );
}

export default QuestionAttempt;
