//
//
// Profile
//
//

import { Container, Title, Flex, Button, Modal, ActionIcon, TextInput, Group, Textarea, Select, Checkbox } from "@mantine/core"
import { IconCheck, IconEdit, IconTrash } from "@tabler/icons-react"
import PaginationControl from "../../components/PaginationControl"
import { DataTable } from "mantine-datatable"
import { useEffect, useState, useRef } from "react"
import Api from "../../api"
import { } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Personality, PersonalityDataTable, AzureVoice } from "../../interfaces";
import UpdateDrawer from "../../components/UpdateDrawer";
import { onResourceUpdate } from "../../utils"
import { useAuth0 } from "@auth0/auth0-react"
import { useTeam } from "../../contexts/TeamContext";


function Profile() {
    const { team } = useTeam()
    const { getAccessTokenSilently } = useAuth0()
    const [personalities, setPersonalities] = useState<Personality[]>([])
    const pageLength = 20
    const [isLoading, setIsLoading] = useState(false)
    const [totalPersonalities, setTotalPersonalities] = useState(0)
    const personalitiesRef = useRef<Personality[]>([])
    const [opened, setOpened] = useState(false)
    const [personalitySelected, setPersonalitySelected] = useState<Personality>()
    const [openedModel, setOpenedModel] = useState(false)
    const [page, setPage] = useState(1)
    const [accessToken, setAccessToken] = useState('')
    const [azureVoices, setAzureVoices] = useState<AzureVoice[]>([])
    const [personalitiesDataTable, setPersonalitiesDataTable] = useState<PersonalityDataTable[]>([])

    const form = useForm({
        initialValues: {
            team_uuid: team!.uuid,
            name: "",
            description: "",
            context: "",
            azure_voice_uuid: undefined,
            active: false,
            default_text_max_sms: "",
            max_sms: 5

        },

        validate: {
            //name: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid name'),
        },
    });

    useEffect(() => {
        const fetchAccessToken = async () => {
          try {
            const token = await getAccessTokenSilently({
              authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
              },
            });
            setAccessToken(token)
          } catch (error) {
            console.error(error);
          }
        };
        fetchAccessToken();
      }, []);

    useEffect(() => {
        fetchPersonalities()
      }, [accessToken, page, team])

    useEffect(() => {
        fetchAzureVoices()
      }, [accessToken])

      useEffect(() => {
        form.setFieldValue('team_uuid', team!.uuid)
      }, [team!.uuid])

    function onSubmit(values: any) {
        Api.createPersonality(
            accessToken,
            values.name,
            values.description,
            values.context,
            values.azure_voice_uuid,
            values.active,
            values.team_uuid,
            values.default_text_max_sms,
            values.max_sms
        )
            .then(() => {
                fetchPersonalities()
                form.reset()
                onCloseModal()
            })
    }

    function onUpdate(values: any) {
        setIsLoading(true)
        Api.updatePersonality(accessToken, personalitySelected!.uuid, values)
            .then((updated) => {
                    personalitiesRef.current = onResourceUpdate(updated, personalities)
                    setPersonalities(personalitiesRef.current)
                    const data = []
                    for (let i = 0; i < personalitiesRef.current.length; i++) {
                        const personality = personalitiesRef.current[i]
                        data.push({uuid: personality.uuid ,name: personality.name, description: personality.description, context: personality.context, azure_voice_name: personality.azure_voice?.name ? personality.azure_voice.name : '', active: personality.active})
                    }                
                    setPersonalitiesDataTable(data)
                    setIsLoading(false)
            })
    }

    async function fetchAzureVoices() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })
        setIsLoading(true)
         Api.getAzureVoices(accessToken)
            .then((pageAzureVoices) => {
                setAzureVoices(pageAzureVoices["items"])                
                setIsLoading(false)
            }
            )
    }

    async function fetchPersonalities() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })

        if (team == null) {
            return
        }
        
        setIsLoading(true)
         Api.getPersonalities(accessToken, team.uuid, page, pageLength)
            .then((pagePersonalities) => {
                personalitiesRef.current = pagePersonalities["items"]       
                setPersonalities(personalitiesRef.current)
                const data = []
                for (let i = 0; i < personalitiesRef.current.length; i++) {
                    const personality = personalitiesRef.current[i]
                    data.push({uuid: personality.uuid ,name: personality.name, description: personality.description, context: personality.context, azure_voice_name: personality.azure_voice?.name ? personality.azure_voice.name : '', active: personality.active})
                }                
                setPersonalitiesDataTable(data)
                setTotalPersonalities(pagePersonalities.total)
                setIsLoading(false)
            }
            )
    }


    function onOpenModal() {
        setOpenedModel(true)
    }
    function onCloseModal() {
        setOpenedModel(false)
    }

    function onClose() {
        setOpened(false)
    }

    function onOpen(val: PersonalityDataTable) {
        setOpened(true)
        setPersonalitySelected(personalitiesRef.current.find((personality: any) => personality.uuid === val.uuid))
    }

    function onDelete(resource: any) {
        setIsLoading(true)
        Api.deletePersonality(accessToken, resource.uuid)
            .then(() => {
                fetchPersonalities()
                setIsLoading(false)
            })
    }

    return (
        <>
            <Container>
                <Flex justify="space-between" align="center" mt="xl" mb="xl">
                    <Title> Profiles</Title>
                    <Button onClick={() => onOpenModal()}> Create personality</Button>
                </Flex>
                <DataTable
                    backgroundColor={{ dark: '#232b25', light: '2596be' }}
                    minHeight={personalities.length > 0 ? undefined : 200}
                    records={personalitiesDataTable}
                    fetching={isLoading}
                    columns={[{ accessor: 'name' }, { accessor: 'azure_voice_name', title: 'Azure Voice' }, { accessor: 'description' }, { accessor: 'context' }, { accessor: 'active' } , { accessor: 'option' }]}
                    defaultColumnRender={(row, _, accessor) => {
                        const data = row[accessor as keyof typeof row];                
                        return typeof data == 'string' ? data : typeof data == 'boolean' ? 
                            ( data && ( <IconCheck color="green"/> ) )
                        : <>

                            <ActionIcon
                                key="edit"
                                size="sm"
                                variant="subtle"
                                color="blue"
                                onClick={() => onOpen(row)}
                            >
                                <IconEdit size={16} />
                            </ActionIcon>

                            <ActionIcon
                                key="delete"
                                size="sm"
                                variant="subtle"
                                color="red"
                                onClick={() => onDelete(row)}
                            >
                                <IconTrash size={16} />
                            </ActionIcon>
                        </>;
                    }}
                />
                <PaginationControl
                    totalElements={totalPersonalities}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
            {personalitySelected != null &&
                <UpdateDrawer opened={opened} onClose={onClose} personality={personalitySelected} onUpdate={onUpdate} />
            }

            <Modal opened={openedModel} onClose={onCloseModal} title="New Personality">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput
                        label="Name"
                        {...form.getInputProps("name")}
                    />
                    <TextInput
                        label="Description"
                        {...form.getInputProps("description")}
                    />
                    <Textarea
                        label="Context"
                        {...form.getInputProps("context")}
                    />
                    <Select label="Azure Voice" 
                        {...form.getInputProps("azure_voice_uuid")}
                        placeholder="Select Azure voice"
                        clearable
                        searchable                        
                        data={azureVoices.map((voice) => ({ value: voice.uuid, label: voice.name }))}                        
                    />
                    <TextInput
                        label="Number Max messages"
                        {...form.getInputProps("max_sms")}
                    />
                    <TextInput
                        label="Text Max messages"
                        {...form.getInputProps("default_text_max_sms")}
                    />
                    <Checkbox
                        label="Active"
                        style={{marginTop: '10px'}}
                        {...form.getInputProps("active" , { type: 'checkbox' })}
                    />
                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Submit</Button>
                    </Group>
                </form>
            </Modal>
        </>
    )
}
export default Profile