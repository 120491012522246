//
//
//  Lesson
//
//


import { ActionIcon, Badge, Button, Flex, Title, Text, Menu, Loader, Tooltip } from "@mantine/core";
import { IconDots, IconDownload, IconEdit, IconExclamationCircle, IconEye, IconFileMusic, IconPlayerPlay, IconPlus, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { modals } from "@mantine/modals";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDate } from "../../utils.ts";
import Api from "../../api.ts";
import NewArticleModal from "./Components/NewArticleModal.tsx";
import EditLessonModal from "./Components/EditLessonModal.tsx";
import { notifications } from '@mantine/notifications';
import { useTeam } from "../../contexts/TeamContext.tsx";
import { AzureVoice, LessonArticle, LessonInterface } from "../../interfaces.ts";
import AudioCreationModal from "./Components/AudioCreationModal.tsx";
import { useSocket } from "../../contexts/SocketContext.tsx";


const PAGE_SIZE = 25

function Lesson({ lesson, onUpdate, onDelete }: { lesson: any, onUpdate: (lesson: LessonInterface) => void, onDelete: (lesson: LessonInterface) => void }) {
    const navigate = useNavigate()
    const { role } = useTeam()
    const { socket } = useSocket()
    const { getAccessTokenSilently } = useAuth0()
    const [totalArticles, setTotalArticles] = useState(0)
    const [articlesPage, setArticlesPage] = useState(1)
    const [azureVoices, setAzureVoices] = useState<AzureVoice[]>([])
    const [articles, setArticles] = useState<LessonArticle[]>([])

    //loaders
    const [articlesLoading, setArticlesLoading] = useState<boolean>(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })

        return accessToken
    }

    async function fetchAzureVoices() {
        Api.getAzureVoices(await getAccessToken()).then((pageAzureVoices) => {
            setAzureVoices(pageAzureVoices["items"])
        }
        ).catch(error => {
            console.error(error)
        })
    }


    async function fetchArticles() {
        let articlesPromise
        if (role === 'EDITOR') {
            articlesPromise = Api.getLessonArticles(await getAccessToken(), lesson.uuid, articlesPage, PAGE_SIZE)
        }
        else {
            articlesPromise = Api.getLessonArticlesDeployed(await getAccessToken(), lesson.uuid, articlesPage, PAGE_SIZE)
        }

        articlesPromise
            .then((response) => {
                setArticles(response["items"])
                setTotalArticles(response["total"])
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setArticlesLoading(false)
            })

    }

    useEffect(() => {
        setArticlesLoading(true)
        fetchArticles()
        fetchAzureVoices()
    }, [lesson.uuid, articlesPage])


    const editLessonModal = () => {
        modals.open({
            title: "Edit Lesson",
            children: (
                <EditLessonModal
                    lesson={lesson}
                    onSubmit={(values) => {
                        Api.updateTeamLesson(getAccessTokenSilently, lesson.uuid, values.name, values.description, values.disabled)
                            .then((response: any) => {
                                onUpdate(response)
                            }).catch(error => {
                                console.error(error)
                            }).finally(() => {
                                modals.closeAll()
                            })
                    }
                    }
                    onCancel={() => {
                        modals.closeAll()
                    }}
                />
            )
        })
    }

    const audioCreationModal = async (article: LessonArticle) => {

        modals.open({
            title: "Create Audio File",
            children: (
                <AudioCreationModal
                    voices={azureVoices}
                    onSubmit={async (values) => {
                        if (values.startType === "tts") {
                            Api.createSpeechByArticleContent(await getAccessToken(), article.uuid, values.azure_voice_uuid)
                                .then(() => {
                                    notifications.show({
                                        title: "Audio file is being created",
                                        message: "Please wait for a while. You will be notified when the audio file is ready.",
                                        color: "teal",
                                    });
                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                                .finally(() => {
                                    modals.closeAll();
                                }); 
                        } else if (values.startType === "files") {
                            Api.uploadAudioInArticle(await getAccessToken(), article.uuid, values.file)
                                .then((response) => {
                                    setArticles(articles.map(a => a.uuid === article.uuid ? response : a))
                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                                .finally(() => {
                                    modals.closeAll();
                                });
                        }
                    }}
                />
            )
        })
    }
    const deleteLessonModal = () => {
        modals.openConfirmModal({
            title: "Delete Lesson",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete the lesson? This action is destructive and you will not be able to recover the lesson once it's deleted.
                </Text>
            ),
            labels: { confirm: 'Delete lesson', cancel: "No don't delete it" },
            confirmProps: { color: 'red' },
            onCancel: () => {
                modals.closeAll();
            },
            onConfirm: async () => {
                try {
                    const accessToken = await getAccessToken();
                    await Api.deleteTeamLesson(accessToken, lesson.uuid);
                    onDelete(lesson);
                } catch (error) {
                    const message = 'Oops... something went wrong. Try again later'
                    notifications.show(
                        {
                            title: 'Error',
                            message,
                            color: 'red'
                        }
                    )

                }
            },
        });
    };


    const addArticleModal = () => {
        modals.open({
            title: "Add Article",
            children: (
                <NewArticleModal
                    onSubmit={(values) => {
                        async function createLessonArticle() {
                            const article = await Api.createLessonArticle(await getAccessToken(), lesson.uuid, values.title)
                            if ("file" in values) {
                                await Api.createLessonArticleContentByFile(await getAccessToken(), article.uuid, values.file)
                            }

                            return article
                        }

                        createLessonArticle()
                            .then(article => {
                                navigate("/lessons/articles/" + article.uuid)
                            }).catch(error => {
                                console.error(error)
                            }).finally(() => {
                                modals.closeAll()
                            })
                    }}
                />
            ),
        })
    }

    const openDeleteArticleModal = (article: any) => {
        modals.openConfirmModal({
            title: "Delete Article",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete the article? This action is destructive and you will not be able to recover the article once it's deleted.
                </Text>
            ),
            labels: { confirm: 'Delete article', cancel: "No don't delete it" },
            confirmProps: { color: 'red' },
            onConfirm: async () => {
                Api.deleteLessonArticle(await getAccessToken(), article.uuid)
                    .then(() => {
                        setArticles(articles.filter(a => a.uuid !== article.uuid))
                    }).catch(error => {
                        console.error(error)
                    }).finally(() => {

                    })
            },
        })
    }


    useEffect(() => {
        if (socket == null) {
            return
        }

        socket.on("lesson:update", (updatedLesson: LessonInterface) => {
            if (updatedLesson.uuid === lesson.uuid) {
                onUpdate(updatedLesson)
            }
        })

        socket.on("lesson:delete", (updatedArticle: LessonArticle) => {
            if (updatedArticle.lesson_uuid === lesson.uuid) {
                setArticles([...articles, updatedArticle])
            }
        })

        socket.on("lesson_article:create", (updatedArticle: LessonArticle) => {
            if (updatedArticle.lesson_uuid === lesson.uuid) {
                setArticles([...articles, updatedArticle])
            }
        })

        socket.on("lesson_article:update", (data: LessonArticle) => {
            if (data.lesson_uuid === lesson.uuid) {
                setArticlesLoading(true)
                setArticles([])
                fetchArticles()
                }
        });
        
        socket.on("lesson_article:delete", (data: LessonArticle) => {
            if (data.lesson_uuid === lesson.uuid) {
                setArticlesLoading(true)
                setArticles([])
                fetchArticles()
            }
        })

        return () => {
            socket.off("lesson:update")
            socket.off("lesson_article:create")
            socket.off("lesson_article:update")
            socket.off("lesson_article:delete")
        }
    }, [socket])

    return (
        <Flex direction="column" style={{ width: "100%" }} gap={20}>
            <Flex direction="row" justify="space-between" style={{ width: "100%" }}>
                <Flex direction="column" gap={10}>
                    <Title order={5} mt={20}>{lesson.name}</Title>
                    <Badge color={lesson.disabled ? "red" : "lime"}>{lesson.disabled ? "Disabled" : "Enabled"}</Badge>
                    <Text>{lesson.description}</Text>
                </Flex>
                {role === 'EDITOR' && (
                    <Flex align="center" gap={20}>
                        <Button leftSection={<IconPlus size={16} />} variant="outline" size="xs" onClick={addArticleModal}>
                            Add Article
                        </Button>
                        <Menu shadow="md" width={200} >
                            <Menu.Target>
                                <ActionIcon variant="outline">
                                    <IconDots />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item leftSection={<IconEdit size={16} />} onClick={editLessonModal}>
                                    Edit
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item
                                    color="red"
                                    onClick={deleteLessonModal}
                                    leftSection={<IconTrash size={16} />}
                                >
                                    Delete
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                )}
            </Flex>
            <Flex direction="column">
                <Title order={3} mb={12}>Articles</Title>
                <DataTable
                    fetching={articlesLoading}
                    columns={[
                        {
                            accessor: "status",
                            width: 120,
                            textAlign: "left",
                            render: ({ is_processing, has_error, is_draft_deployed }) => {
                                let statusNode = undefined
                                if (is_processing) {
                                    statusNode = <Loader size="xs" color="blue" />
                                } else if (has_error) {
                                    statusNode = <IconExclamationCircle size={18} color="red" />
                                } else if (is_draft_deployed) {
                                    statusNode = <Badge color="teal" radius="xs" variant="outline">Deployed</Badge>
                                } else {
                                    statusNode = <Badge color="blue" radius="xs" variant="outline">Draft</Badge>
                                }
                                return statusNode
                            }
                        },
                        {
                            accessor: "title",
                            textAlign: "left",
                            render: ({ title }) => {
                                return (
                                    <Text size="sm" fw={500}>{title}</Text>
                                )
                            }
                        },
                        {
                            accessor: "last_deployed_at",
                            title: "Last deployed at",
                            render: ({ last_deployed_at }) => {
                                return (
                                    <Text size="sm">{last_deployed_at == null ? "-" : formatDate(new Date(last_deployed_at))}</Text>
                                )
                            }
                        },
                        {
                            accessor: "actions",
                            textAlign: "right",
                            render: (article) => {
                                return (
                                    <Flex align="center" justify="flex-end" gap={6}>
                                        {role === 'VIEWER' && (
                                            <div>
                                                {article.audio_url && (
                                                    <a href={article.audio_url} target="_blank" rel="noopener noreferrer">
                                                        <ActionIcon variant="subtle">
                                                            <IconPlayerPlay size={14} />
                                                        </ActionIcon>
                                                    </a>
                                                )}
                                                {article.source_url && (
                                                    <a href={article.source_url} target="_blank" rel="noopener noreferrer">
                                                        <ActionIcon variant="subtle">
                                                            <IconDownload size={14} />
                                                        </ActionIcon>
                                                    </a>
                                                )}
                                                <ActionIcon variant="subtle">
                                                    <IconEye size={14} />
                                                </ActionIcon>
                                            </div>
                                        )}
                                        {role === 'EDITOR' && (
                                            <div>
                                                {article.audio_url && (
                                                    <a href={article.audio_url} target="_blank" rel="noopener noreferrer">
                                                        <ActionIcon variant="subtle">
                                                            <IconPlayerPlay size={14} />
                                                        </ActionIcon>
                                                    </a>
                                                )}
                                                {article.source_url && (
                                                    <a href={article.source_url} target="_blank" rel="noopener noreferrer">
                                                        <ActionIcon variant="subtle">
                                                            <IconDownload size={14} />
                                                        </ActionIcon>
                                                    </a>
                                                )}
                                                {article.is_draft_deployed && (
                                                    <Tooltip label="Generate audio file from article content">
                                                    <ActionIcon variant="subtle" onClick={() => audioCreationModal(article)} disabled={!azureVoices}>
                                                        <IconFileMusic size={14} />
                                                    </ActionIcon>
                                                </Tooltip>
                                                )}
                                                <ActionIcon variant="subtle" component={Link} to={"/lessons/articles/" + article.uuid}>
                                                    <IconEdit size={14} />
                                                </ActionIcon>
                                                <ActionIcon variant="subtle" onClick={() => openDeleteArticleModal(article)}>
                                                    <IconTrash size={14} />
                                                </ActionIcon>
                                            </div>
                                        )}
                                    </Flex>
                                )
                            }
                        }
                    ]}
                    records={articles}
                    minHeight={articles.length > 0 ? 0 : 300}
                    totalRecords={totalArticles}
                    recordsPerPage={PAGE_SIZE}
                    page={articlesPage}
                    onPageChange={(p) => setArticlesPage(p)}
                />
            </Flex>
        </Flex>
    )
}

export default Lesson