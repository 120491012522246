//
//
//  Switch Teams Modal
//
//

import {Modal, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Team} from "../interfaces.ts";
import Api from "../api.ts";
import {IconCircleCheckFilled} from "@tabler/icons-react";
import useAdmin from "../hooks/admin.ts";

const PAGE_SIZE = 10

function SwitchTeamsModal({opened, onClose, withCloseButton = true, currentTeam, onSelect}: {opened: boolean, onClose: () => void, withCloseButton?: boolean, currentTeam?: Team, onSelect: (team: Team) => void}) {
    const isAdmin = useAdmin()
    const [searchQuery, setSearchQuery] = useState("")
    const {getAccessTokenSilently} = useAuth0()
    const [total, setTotal] = useState(0)
    const [loadPage, setLoadPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(false)
    const [teams, setTeams] = useState<Team[]>([])
    const [loading, setLoading] = useState(true)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function fetchTeams() {
        setLoading(true)
        Api.getTeamsBelong(await getAccessToken(), loadPage, PAGE_SIZE)
        .then((teams: any) => {
            setTeams(teams["items"])
            setTotal(teams["total"])

            if (teams["items"].length === 0) {
                setCurrentPage(false)
            } else {
                setCurrentPage(teams["page"])
            }
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTeams()
    }, [getAccessTokenSilently, isAdmin, loadPage])

    useEffect(() => {
        // Search when user stops typing
        const timeOutId = setTimeout(() => {
            fetchTeams()
        }, 500)
        return () => clearTimeout(timeOutId);
    }, [getAccessTokenSilently, searchQuery])


    return (
        <Modal opened={opened} onClose={onClose} title={isAdmin ? "Teams" : "Your Teams"} withCloseButton={withCloseButton}>
            <TextInput
                placeholder="Search teams ..."
                mb={6}
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
            <DataTable
                noHeader
                withRowBorders={false}
                verticalSpacing="sm"
                highlightOnHover={true}
                fetching={loading}
                noRecordsText="You are not part of any team"
                onRowClick={({record}: {record: Team}) => {
                    onSelect(record)
                }}
                columns={[
                    {
                        accessor: "name" ,
                        width: "100%",
                        render: ({name}: {name: string}) => {
                            return <Text fw={500}>{name}</Text>
                        }
                    },
                    {
                        accessor: "",
                        render: ({uuid}: {uuid: string}) => {
                            if (uuid === currentTeam?.uuid) {
                                return <IconCircleCheckFilled size={20}/>
                            }
                        }
                    }
                ]}
                records={teams}
                idAccessor="id"
                minHeight={teams.length > 0 ? 0 : 200}
                totalRecords={total}
                recordsPerPage={PAGE_SIZE}
                // @ts-expect-error
                page={currentPage}
                onPageChange={(p) => setLoadPage(p)}
            />
        </Modal>
    )
}

export default SwitchTeamsModal