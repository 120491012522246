//
//
//  App Layout
//
//

import {Link, useLocation} from "react-router-dom";

import classes from "./AppLayout.module.css"
import {Container, Group, Image, UnstyledButton, Badge} from "@mantine/core";
import {IconLogout, IconUsers} from "@tabler/icons-react";
import logo from "../assets/logo.svg"
import {generateColorFromString, getMetaContent} from "../utils.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode } from "react";



function AppLayout({children}: {children: ReactNode}) {

    
    const {pathname} = useLocation()
    const { logout } = useAuth0();

    const appName = getMetaContent("APP_NAME")

    const links = [
        {link: "/admin/teams", label: 'Teams', icon: IconUsers, active: pathname === "/admin/teams"}
    ]

    return (
        <>
            <nav className={classes.navbar}>
                <div className={classes.navbarMain}>
                    <Group className={classes.header} justify="center">
                        <Link to="/">
                            <Image
                                src={logo}
                                h={60}
                                w="auto"
                                fit="contain"
                            />
                        </Link>
                        {appName && <Badge mt="xs" color={generateColorFromString(appName)}>{appName}</Badge>}
                    </Group>
                    {links.map(link => {
                        
                        return (
                            <Link
                                className={classes.link}
                                data-active={link.active || undefined}
                                to={link.link}
                                key={link.link}
                            >
                                <link.icon className={classes.linkIcon} stroke={1.5}/>
                                <span>{link.label}</span>
                            </Link>
                        )
                        
                    })}
                </div>

                <div className={classes.footer}>
                    <UnstyledButton className={classes.link} onClick={()=> logout({ logoutParams: { returnTo: window.location.origin } })}>
                        <IconLogout className={classes.linkIcon} stroke={1.5}/>
                        <span>Logout</span>
                    </UnstyledButton>
                </div>
            </nav>
            <main className={classes.main}>
                <Container>
                    {children}
                </Container>
            </main>
        </>
    )
}

export default AppLayout
