import {
    Flex,
    Container,
    Grid,
    Paper,
    Text,
    Title,
    Alert,
    CheckIcon,
    Checkbox,
    Skeleton,
    Box,
} from "@mantine/core";
import DashboardHeader from "../../../components/DashboardHeader";
import { useEffect, useState } from "react";
import {
    CompletedQuestionInterface,
    ExamAnswerInterface,
    ExamAttemptCompletedInterface,
    ExamAttemptInterface,
    ExamInterface
} from "../../../interfaces";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Api from "../../../api";
import { intToHoursMinutesSeconds } from "../../../utils";


function ExamPreview() {

    const [exam, setExam] = useState<ExamInterface>();
    const [examAttempt, setExamAttempt] = useState<ExamAttemptInterface>();
    const [examAttemptCompleted, setExamAttemptCompleted] = useState<ExamAttemptCompletedInterface>();
    const { getAccessTokenSilently } = useAuth0();
    const params = useParams();


    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            },
        });

        return accessToken;
    }

    async function fetchExamAttemptCompleted() {
        if (!params.examAttemptUuid) {
            return;
        }

        Api.getExamAttempt(await getAccessToken(), params.examAttemptUuid).then((attempt) => {
            setExamAttempt(attempt);
            setExam(attempt.exam)
        });
        Api.getExamAttemptCompleted(await getAccessToken(), params.examAttemptUuid).then((attempt) => {
            setExamAttemptCompleted(attempt);
        });
    
    }

    useEffect(() => {
        fetchExamAttemptCompleted()
    }, [params]);

    const header = examAttempt ? (
        <DashboardHeader
          title={exam?.name}
        />
      ) : null;

    if (exam) {
        return (
            <div>
                {header}
                <Flex>
                {examAttempt && examAttempt.status === 'COMPLETED' && (
                <Grid grow gutter="xs">
                    <Grid.Col span={3}>
                        <Flex
                            p={50}
                            gap={20}
                            direction={"column"}
                            justify={"center"}
                            align={"center"}
                        >
                            <Paper p="md" shadow="sm" w={"100%"}>
                                <Title my={"sm"} size="h4" c={"blue"}>
                                    Exam
                                </Title>
                                <Text fw={500} size="md">
                                    Name: {exam.name}
                                </Text>
                            </Paper>
                            <Paper p="md" shadow="sm" w={"100%"}>
                                <Title my={"sm"} size="h4" c={"blue"}>
                                    Time
                                </Title>
                                <Text fw={500} size="md">
                                    {intToHoursMinutesSeconds(examAttempt?.duration)}
                                </Text>
                            </Paper>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <Container>
                            <Flex p={50} direction={"column"} gap={10}>
                                <div>
                                <Alert variant="light" color="green" title="Exam Completed" icon={<CheckIcon />}>
                                    Your final score is {examAttempt.mark} / 10
                                </Alert>
                                {!examAttemptCompleted && 
                                <Box>
                                    <Skeleton height={200} visible={true} mt={10}/>
                                    <Skeleton height={200} visible={true} mt={10}/>
                                    <Skeleton height={200} visible={true} mt={10}/>
                                </Box>
                                }
                                {examAttemptCompleted?.questions.map((question: CompletedQuestionInterface) => (
                                    <Paper p={"xl"} radius={"sm"} shadow="sm" mt={10}>
                                    <Flex justify={"space-between"}>
                                      <Text fw={700}>{question.text}</Text>
                                    </Flex>
                                    <Flex direction={"column"} m={10} gap={10}>
                                        {question.answers.map((answer: ExamAnswerInterface) => (
                                          <Grid key={answer.uuid} columns={12} w={'full'}>
                                            <Grid.Col span={11}>
                                            <Text
                                                style={{ color: answer.is_correct ? 'green' : (question.selected_answer === answer.uuid ? 'red' : 'black') }}
                                                >
                                                {answer.text}
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col span={1}>
                                              <Flex justify={"center"}>
                                                <Checkbox
                                                  disabled={true} mt={10}
                                                  checked={question.selected_answer === answer.uuid} />
                                              </Flex>
                                            </Grid.Col>
                                          </Grid>
                                        ))}
                                    </Flex>
                                  </Paper>
                                ))}
                                </div> 
                            </Flex>
                        </Container>
                    </Grid.Col>
                </Grid>
                )}
                </Flex>
            </div>
        );
    }
}

export default ExamPreview;
