//
//
//  Article
//
//

import {Link, useParams} from "react-router-dom";
import DashboardHeader from "../../../components/DashboardHeader.tsx";
import {Button, Container, Flex, Title, Text} from "@mantine/core";
import {useEffect, useState} from "react";
import {IconArrowLeft} from "@tabler/icons-react";
import {useEditor} from '@tiptap/react'
import {StarterKit} from "@tiptap/starter-kit";
import {RichTextEditor} from "@mantine/tiptap";
import {Placeholder} from "@tiptap/extension-placeholder";
import Api from "../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {notifications} from "@mantine/notifications";
import classes from "./Article.module.css"
import { LessonArticle } from "../../../interfaces.ts";


function LessonArticle() {
    const [isDeployed, setIsDeployed] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [deployLoading, setDeployLoading] = useState(false)
    const [modified, setModified] = useState(false)
    const {getAccessTokenSilently} = useAuth0()
    const [article, setArticle] = useState<LessonArticle | null>(null)
    const params = useParams()

    const editor = useEditor({
        extensions: [
            StarterKit,
            Placeholder.configure({ placeholder: 'Start writing your article ...' }),
        ],
        content: "",
        autofocus: true,
    })

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })
       return accessToken
    }

    useEffect(() => {
        function saveContent(e: KeyboardEvent) {
            if (e.key === "s" && (/macintosh/i.test(navigator.userAgent) ? e.metaKey : e.ctrlKey)) {
                e.preventDefault()
                onSave()
                    .catch(error => {
                        console.error(error)
                    })
            }
        }
        window.addEventListener("keydown", saveContent)

        return () => {
            window.removeEventListener("keydown", saveContent)
        }
    }, [editor])

    useEffect(() => {
        if (editor == null) {
            return
        }

        editor.on("update", () => {
            setModified(true)
        })
    }, [editor])

    async function fetchLessonArticle() {
        if (!params.articleUuid) {
            return
        }
        await Api.getLessonArticle(await getAccessToken(), params.articleUuid!)
            .then((response) => {
                setArticle(response)
                editor?.commands?.setContent(response.draft_content.replaceAll("\n", "<br>"))
                })
    }

    useEffect(() => {
        if (!params.articleUuid) {
            return
        }
        fetchLessonArticle()

    }, [params.articleUuid, editor])


    useEffect(() => {
        if (editor == null) {
            return
        }

        editor.on("update", () => {
            setModified(true)
        })
    }, [editor])

    async function onSave() {
        
        if (params.articleUuid == null) {
            return Promise.resolve()
        }

        if (!modified) {
            return Promise.resolve()
        }

        setIsDeployed(false)
        setSaveLoading(true)
        setModified(false)
        
        const content = editor?.getText() || ""
        try {
            await Api.updateLessonArticleContent(await getAccessToken(), params.articleUuid!, content);
            notifications.show({
                title: "Saved!",
                message: "Article saved successfully!",
                color: "teal",
            });
        } finally {
            setSaveLoading(false);
        }
    }

    async function onDeploy() {
        if (params.articleUuid == null) {
            return
        }

        setDeployLoading(true)
        onSave()
            .then( async () => {
                return Api.patchLessonArticle(await getAccessToken(), params.articleUuid!, {is_draft_deployed: true, deploy_content: article?.draft_content, last_deployed_at: new Date().toISOString()})
            }).then(() => {
                setIsDeployed(true)
                notifications.show({
                    title: "Deployed!",
                    message: "Article deployed successfully! It may take a while to reflect changes in the lesson.",
                    color: "teal",
                })
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setDeployLoading(false)
            })
    }

    const BackToLessonsButton = (
        <Button
            component={Link}
            to="/lessons"
            variant="subtle"
            leftSection={<IconArrowLeft size={16}/>}
        >
            Lessons
        </Button>
    )

    if (article == null)  {
        return (
            <>
                <DashboardHeader
                    title=""
                    leftSection={BackToLessonsButton}
                />
            </>
        )
    }

    if (article.is_processing) {
        return (
            <>   <div className={classes.header}>
                    <DashboardHeader
                        title={article.title}
                        leftSection={BackToLessonsButton}
                    />
                </div>
                <Flex align="center" direction="column" style={{width: "100%"}} mt={60}>
                    <Title order={1} mb={20}>Processing your article 🚀</Title>
                    <Text size="lg" fw={500}>Grab a coffee because it may take a while ...</Text>
                </Flex>
            </>
        )
    }

    if (article.has_error) {
        return (
            <>
                <DashboardHeader
                    title={article.title}
                    leftSection={BackToLessonsButton}
                />
                <Flex align="center" direction="column" style={{width: "100%"}} mt={60}>
                    <Title order={1} mb={20}>An error occurred</Title>
                    <Text size="lg" fw={500}>We couldn't process your article</Text>
                </Flex>
            </>
        )
    }

    return (
        <div className={classes.content}>
            <div className={classes.header}>
                <DashboardHeader
                    title={article.title}
                    leftSection={BackToLessonsButton}
                    rightSection={
                        <Flex gap={12}>
                            <Button onClick={onSave} disabled={!modified} variant="outline" loading={saveLoading}>Save Draft</Button>
                            <Button onClick={onDeploy} disabled={!modified && isDeployed} loading={deployLoading}>Deploy</Button>
                        </Flex>
                    }
                />
            </div>
            <div style={{backgroundColor: "rgb(247, 247, 247)", minHeight: "calc(100vh)", paddingTop: "5rem"}}>
                <Container>
                    <RichTextEditor editor={editor} mt={25}>
                        <RichTextEditor.Content />
                    </RichTextEditor>
                </Container>
            </div>
        </div>
    )
}

export default LessonArticle