import { Button } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons-react"
import { Link } from "react-router-dom"

const BackToExamsButton = (
    <Button
        component={Link}
        to="/exams"
        variant="subtle"
        leftSection={<IconArrowLeft size={16}/>}
    >
        Exams
    </Button>
)

export default BackToExamsButton