//
//
//  New Lesson Form
//
//

import {useForm} from "@mantine/form";
import {Button, FileInput, Flex, TextInput} from "@mantine/core";
import {useState} from "react";

function NewArticleFileForm({onSubmit, onCancel}: {onSubmit: (values: any) => void, onCancel: () => void}) {
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            title: "",
            file: ""
        }
    })

    function onSubmitInner(values: any)  {
        setLoading(true)
        onSubmit(values)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
            <TextInput
                required
                withAsterisk
                label="Title"
                placeholder="Super duper article"
                {...form.getInputProps("title")}
            />
            <FileInput
                mt={12}
                required
                withAsterisk
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png,image/jpeg, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint"
                label="File"
                multiple={false}
                {...form.getInputProps("file")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit" loading={loading}>Submit</Button>
            </Flex>
        </form>
    )
}

export default NewArticleFileForm